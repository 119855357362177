<div *ngIf="isSocialMediaShareEnabled" class="d-inline-block" ngbDropdown placement="left-top auto">
  <button
    #shareIconsToggleButton
    [attr.aria-label]="'share' | translate"
    [ngClass]="{
            'small-button': smallButton,
            'big-button': bigButton,
            'button-labelled': !smallButton,
            'options-toggle-menu-button-disabled': disabled,
          }"
    aria-expanded="false"
    aria-haspopup="menu"
    class="app-button options-toggle-button"
    data-automation-id="share-toggle-menu-button"
    [disabled]="disabled"
    tippy="{{ 'share' | translate }}"
    ngbDropdownToggle
    type="button"
  >
    <span aria-hidden="true" class="icon options-icon">
      <fa-icon [icon]="faShareNodes"></fa-icon>
    </span>
    <span
      *ngIf="!smallButton"
      [ngClass]="{
      'text': !bigButton,
    }"
      translate
      >share</span
    >
  </button>
  <div
    class="py-2"
    ngbDropdownMenu
    role="menu"
    data-automation-id="options-dropdown-menu"
  >
    <button
      [attr.aria-label]="'shareOnX' | translate"
      class="d-block py-2 px-3 w-100 h-auto app-button option-button"
      data-automation-id="twitter-share"
      aria-haspopup="dialog"
      ngbDropdownItem
      role="menuitem"
      (click)="shareOnTwitter()"
    >
      <fa-icon [icon]="faXTwitter"></fa-icon>
      <span
        class="option-button-label ml-2 text-capitalize"
        translate
        >x</span
      >
    </button>
    <button
      class="d-block py-2 px-3 w-100 h-auto app-button option-button"
      data-automation-id="facebook-share"
      [attr.aria-label]="'shareOnFacebook' | translate"
      aria-haspopup="dialog"
      ngbDropdownItem
      role="menuitem"
      (click)="shareOnFacebook()"
    >
      <fa-icon [icon]="faFacebook"></fa-icon>
      <span class="option-button-label ml-2 text-capitalize" translate
        >facebook</span
      >
    </button>
  </div>
</div>
