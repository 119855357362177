export enum ApplicationName {
  STAFF = 'STAFF',
  PATRON = 'PATRON'
}

export interface CustomerLanguage {
  enabled: boolean;
  customTranslationEnabled: boolean;
  translationUploaded?: boolean;
  id?: string;
  language: {
    displayName: string;
    id: string;
    name: string;
  };
  application: ApplicationName;
}
