import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { areEqualDeeply } from 'shared/utils/are-equal-deeply';
import { Entity, FormatGroup, PersonalList } from '../../../entity/models/entity';
import { loginFromBookmarkButton, setBookmarkButtonState, stopFocusBookmarkButton } from '../../actions/list.actions';
import { BookmarkButtonState } from '../../models/bookmark-button-state';
import { ListItemEntity, ListWithItemsCount } from '../../models/list';
import { SelectListTrigger } from '../../models/select-list';
import { getEntityBookmarked, getFocusBookmarkButton, getLists } from '../../reducers/list.reducer';
import { ListStoreAdapterService } from '../../services/list-store-adapter.service';
import { ListTransformerService } from '../../services/list-transformer.service';
import { faBookmark as savedBookmarkIcon } from '@fortawesome/pro-solid-svg-icons';
import { faBookmark as bookmarkIcon } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-bookmark-button',
  templateUrl: './bookmark-button.component.html',
  styleUrls: ['./bookmark-button.component.scss'],
})
export class BookmarkButtonComponent implements OnInit, OnDestroy {
  @Input() public fgOrEntity: FormatGroup | Entity;
  @Input() public ariaDescribedBy = '';
  @Input() public recordId: string;
  @ViewChild('dropdown') public dropdown: NgbDropdown;
  @ViewChild('dropdownMenu', {static: true}) public dropdownMenu: ElementRef;
  public readonly savedBookmarkIcon = savedBookmarkIcon;
  public readonly bookmarkIcon = bookmarkIcon;
  public state: BookmarkButtonState;
  public dropdownOpened: boolean;
  public listItemEntity: ListItemEntity;
  public lists: ListWithItemsCount[];
  // TODO fix highlight of selected items in dropdown
  public personalLists: PersonalList[];
  public justAddedList?: ListWithItemsCount;
  public readonly stateEnum = BookmarkButtonState;
  public readonly selectListTriggerEnum = SelectListTrigger;
  private readonly subscription = new Subscription();
  private justAddedListId: string;

  constructor(
    private readonly store: Store,
    private readonly listStoreAdapterService: ListStoreAdapterService,
    private readonly listTransformerService: ListTransformerService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.listItemEntity = this.listTransformerService.transformFgOrEntityToListItemEntity(this.fgOrEntity);
    this.subscription.add(
      this.store.select(getLists)
      .pipe(distinctUntilChanged(areEqualDeeply))
      .subscribe((lists) => {
        this.lists = lists;
        this.setJustAddedList();
        this.cdr.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getEntityBookmarked, {id: this.listItemEntity.id})
      .pipe(
        map((state) => (state) ? state : {bookmarkButtonState: BookmarkButtonState.NONE, personalLists: [], justAddedListId: null}),
        distinctUntilChanged((oldState, newState) => areEqualDeeply(oldState, newState)),
      )
      .subscribe((entityBookmarked) => {
        this.state = entityBookmarked.bookmarkButtonState;
        this.personalLists = entityBookmarked.personalLists;
        this.justAddedListId = entityBookmarked.justAddedListId;
        this.setJustAddedList();
        this.dropdownOpened = BookmarkButtonComponent.isDropdownOpenedState(entityBookmarked.bookmarkButtonState);

        if (this.dropdown) {
          if (this.dropdownOpened) {
            this.dropdown.open();
          } else {
            this.dropdown.close();
          }
        }
        this.cdr.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getFocusBookmarkButton)
      .subscribe((focusBookmarkButton) => {
        if (focusBookmarkButton === this.listItemEntity.id) {
          this.store.dispatch(stopFocusBookmarkButton());
          setTimeout(() => {
            this.dropdownMenu.nativeElement.focus();
          }, 0);
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public syncOpenChange(opened: boolean): void {
    if (!opened && BookmarkButtonComponent.isDropdownOpenedState(this.state)) {
      this.dispatchState(
        (this.state === BookmarkButtonState.NONE_UPDATED)
          ? BookmarkButtonState.NONE
          : BookmarkButtonState.SAVED,
      );
    }
  }

  public onBookmark(event: Event): void {
    this.listItemEntity.selectedTabRecordId = this.recordId;
    this.listItemEntity.bookmarkedRecordId = this.fgOrEntity.lists;
    event.stopPropagation();
    if (this.isSavedState()) {
      this.listStoreAdapterService.removeBookmark(this.listItemEntity);
    } else {
      this.listStoreAdapterService.addBookmarkFromButton(this.listItemEntity);
    }
  }

  public isSavedState(): boolean {
    return ![BookmarkButtonState.NONE, BookmarkButtonState.NONE_UPDATED].includes(this.state);
  }

  public logIn(event: Event): void {
    event.stopPropagation();
    this.store.dispatch(loginFromBookmarkButton({entityId: this.listItemEntity.id}));
  }

  public multiselectDone(): void {
    this.dispatchState((this.personalLists.length) ? BookmarkButtonState.SAVED_UPDATED : BookmarkButtonState.NONE_UPDATED);
  }

  public multiselectCancel(): void {
    this.dispatchState(BookmarkButtonState.SAVED);
  }

  private static isDropdownOpenedState(buttonState: BookmarkButtonState): boolean {
    return [
      BookmarkButtonState.LOGIN,
      BookmarkButtonState.SAVED_IN_MULTIPLE_LISTS,
      BookmarkButtonState.SAVED_TO_SINGLE_LIST,
      BookmarkButtonState.LOADING,
      BookmarkButtonState.NONE_UPDATED,
      BookmarkButtonState.SAVED_UPDATED,
    ].includes(buttonState);
  }

  private dispatchState(bookmarkButtonState: BookmarkButtonState): void {
    this.store.dispatch(setBookmarkButtonState({entityId: this.listItemEntity.id, bookmarkButtonState}));
  }

  private setJustAddedList(): void {
    if (this.justAddedListId) {
      this.justAddedList = this.lists.find((list) => list.id === this.justAddedListId);
    }
  }
}
