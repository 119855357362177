<div class="checkout-card card">
  <div class="container-fluid px-3 py-2">
    <div class="row">
      <div class="col-auto d-none d-sm-block">
        <app-account-card-cover [resource]="card.receipt.resource" (onNavigation)="onNavigation()">
        </app-account-card-cover>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-auto d-block d-sm-none">
            <app-account-card-cover [resource]="card.receipt.resource" (onNavigation)="onNavigation()">
            </app-account-card-cover>
          </div>
          <div class="col pl-0">
            <h2 class="checkout-card-item-title">
              <a *ngIf="frcLink; else noFrcLink"
                 (click)="onNavigation()"
                 (keydown.enter)="onNavigation()"
                 class="activeLink link-content overflow-hidden"
                 data-automation-id="checkout-card-title"
                 [queryParams]="{id: card.receipt.resource.id, entityType: card.receipt.resource.type}"
                 [routerLink]="['/search', 'card']">
                <app-cut-text-with-certain-height
                  [id]="ariaTitleId"
                  [text]="card.receipt.resource.title"
                  [height]="50"
                  [withoutMoreLess]="true"
                  class="checkout-card-title"
                  minLengthToCut="60">
                </app-cut-text-with-certain-height>
              </a>
            </h2>
            <ng-template #noFrcLink>
                <app-cut-text-with-certain-height *ngIf="hasResourceTitle; else noResourceTitle"
                  [id]="ariaTitleId"
                  [text]="card.receipt.resource.title"
                  [height]="50"
                  [withoutMoreLess]="true"
                  class="checkout-card-title checkout-card-title--no-link"
                  data-automation-id="checkout-card-title"
                  minLengthToCut="60">
                </app-cut-text-with-certain-height>
              <ng-template #noResourceTitle>
                <span [id]="ariaTitleId"
                      class="checkout-card-title checkout-card-title--no-link"
                      data-automation-id="checkout-card-title">
                  {{ 'resourceForCheckoutNotAvailable' | translate: {id: card.receipt.id} }}
                </span>
              </ng-template>
            </ng-template>

            <div *ngIf="card.receipt.resource" class="checkout-card-title-designator"
                 data-automation-id="checkout-card-title-designator">
              {{card.receipt.resource.titleDesignator}}
            </div>

            <div class="checkout-card-material-type" data-automation-id="checkout-card-material-type">
              <ng-container *ngIf="card.receipt.resource">
                {{ card.receipt.resource.materialTypeTranslationKey
                    ? (card.receipt.resource.materialTypeTranslationKey | translate)
                    : card.receipt.resource.materialType }}
              </ng-container>
              <ng-container *ngIf="isEcontentVendor()">
                {{ 'fromVendor' | translate: {vendor: formattedVendor[card.receipt.vendor]} }}
              </ng-container>
            </div>
          </div>

          <div class="col-12 col-sm-9 my-2">
            <div
              class="d-flex flex-column flex-sm-row align-items-center align-items-sm-start justify-content-center justify-content-sm-end">
              <div class="d-flex">
                <div class="checkout-card-info pr-sm-2 pr-4" data-automation-id="checkout-card-info-checked-out">
                  <div class="checkout-card-info-title" translate>checkedOutDate</div>
                  <div class="checkout-card-info-value">{{card.receipt.checkOutDate | date}}</div>
                </div>
                <div class="checkout-card-info checkout-card-info--status pl-2"
                     data-automation-id="checkout-card-info-due-date"
                     [ngClass]="{
                     'checkout-card-info--status-success': daysLeftCode !== daysLeftCodeEnum.OVERDUE,
                     'checkout-card-info--status-warning': daysLeftCode === daysLeftCodeEnum.OVERDUE
                     }">
                  <div class="checkout-card-info-title" translate>dueDate</div>
                  <div class="checkout-card-info-value checkout-card-info-value-bold">
                    {{card.receipt.dueDate | date}}
                  </div>
                </div>
              </div>
              <div class="checkout-card-overdue ml-0 mt-3 ml-sm-2 mt-sm-0 text-right"
                   data-automation-id="checkout-card-overdue">
                <ng-container *ngIf="daysLeftCode === daysLeftCodeEnum.OVERDUE">
                  <fa-icon [icon]="exclamationCircleIcon"></fa-icon>
                  {{ 'checkoutOverdue' | translate: {value: daysLeftRounded} }}
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="card.receipt.vendor === vendorEnum.GATES">
          <div class="col-12 mb-2 d-flex justify-content-center justify-content-sm-end">
            <span data-automation-id="checkout-card-renewal-count">
              {{ 'checkoutRenewedTimes' | translate: {renewalCount: card.receipt.renewalCount} }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-center justify-content-sm-end">
            <app-checkout-card-action-button-container
              [action]="card.action"
              [vendor]="card.receipt.vendor"
              [url]="url"
              [ariaDescribedBy]="ariaTitleId"
              [disabled]="card.action?.status === actionStatus.Pending"
              (triggerAction)="handleActionTrigger($event)">
            </app-checkout-card-action-button-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
