<div class="mb-3" *ngIf="isShowCaseScheduleFlagEnabled" [formGroup]="aboutShowcase">
  <h3 class="custom-showcase-publish-title mt-0 mb-3" data-automation-id="custom-showcase-title-publish-manual"
    translate>
    customShowcaseMetadataPublishManually
  </h3>
  <div class="row mb-4 no-gutters align-items-center">
    <!-- Start date and time -->
    <div class="col-auto">
      <div class="form-group">
        <label for="custom-showcase-form-start-date-and-time" class="custom-showcase-label"
          data-automation-id="custom-showcase-form-start-date-and-time-label"
          translate>customShowcaseMetadataStartDate</label>
        <div class="input-group-text input-group-text-datepicker">
          <div class="datepicker-container align-items-center d-flex">
            <!-- Date Picker Input -->
            <input id="custom-showcase-form-start-date-and-time" class="custom-start-datepicker-input mb-0" matInput
              [matDatepicker]="startDatePicker" data-automation-id="custom-showcase-form-start-date"
              placeholder="{{ 'customShowcaseMetadataEnterDate' | translate }}" formControlName="startDate">
            <mat-datepicker-toggle [for]="startDatePicker" class="datepicker-toggle"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </div>
        </div>
      </div>
    </div>

    <!-- Spacer -->
    <div class="col-auto">
      <div class="d-flex align-items-center justify-content-center">
        <span translate data-automation-id="custom-showcase-date-till" class="custom-showcase-label till">
          customShowcaseMetadataTill
        </span>
      </div>
    </div>

    <!-- End date -->
    <div class="col-auto">
      <div class="form-group">
        <label for="custom-showcase-form-end-date-and-time" class="custom-showcase-label"
          data-automation-id="custom-showcase-form-end-date-and-time-label"
          translate>customShowcaseMetadataEndDate</label>
        <div class="input-group-text input-group-text-datepicker">
          <div class="datepicker-container align-items-center d-flex">
            <input id="custom-showcase-form-end-date-and-time" class="custom-end-datepicker-input mb-0" matInput
              [matDatepicker]="endDate" [min]="today" data-automation-id="custom-showcase-form-end-date-and-time"
              placeholder="{{ 'customShowcaseMetadataEnterDate' | translate }}" formControlName="endDate">
            <mat-datepicker-toggle [for]="endDate" class="datepicker-toggle"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <p class="schedule-warning" translate>customShowcaseMetadataScheduleWarningText</p>
    </div>
  </div>

  <!-- Delete showcase on end date -->
  <div class="row mb-4" data-automation-id="delete-showcase-container">
    <div class="col-12">
      <h6 class="custom-delete-showcase-label" data-automation-id="custom-showcase-delete-date-is-reached" translate>
        customShowcaseMetadataDeleteShowcaseWhenDateIsReached
      </h6>

      <div class="form-check form-check-inline">
        <input class="form-check-input mb-0" type="radio" formControlName="deleteExpired"
          data-automation-id="custom-showcase-delete-expired-true" id="delete-showcase-true" [value]="true">
        <label class="form-check-label custom-showcase-date-label custom-showcase-label"
          data-automation-id="custom-showcase-delete-expired-true-label" for="delete-showcase-true"
          translate>customShowcaseMetadataYes</label>
      </div>

      <div class="form-check form-check-inline ml-5">
        <input class="form-check-input mb-0" type="radio" formControlName="deleteExpired"
          data-automation-id="custom-showcase-delete-expired-false" id="delete-showcase-false" [value]="false">
        <label class="form-check-label custom-showcase-date-label custom-showcase-label"
          data-automation-id="custom-showcase-delete-expired-false-label" for="delete-showcase-false"
          translate>customShowcaseMetadataNo</label>
      </div>
    </div>
  </div>

  <!-- Warning text -->
  <div class="row mb-4">
    <div class="col-12">
      <p class="bordered-warning" translate>customShowcaseMetadataWarningText</p>
    </div>
  </div>
</div>
