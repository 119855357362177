import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';
import { listClosed, searchLists, setSearchFacets } from '../../actions/list.actions';
import { ListWithItemsCount, SearchAvailabilityFilter, BookmarksSearchType, BookmarksSearchOptions } from '../../models/list';
import { getOpenedList, getSearchResults } from '../../reducers/list.reducer';

enum StepDisplayedEnum {
  lists,
  singleList,
  both,
}

@Component({
  selector: 'app-searchable-bookmarks',
  templateUrl: './searchable-bookmarks.component.html',
  styleUrls: ['./searchable-bookmarks.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({left: '-100%'})),
      state('false', style({left: '0%'})),
      transition('true <=> false', animate('0.5s ease-out')),
    ]),
  ],
})
export class SearchableBookmarksComponent implements OnInit, OnDestroy {
  public lists: ListWithItemsCount[] = [];
  public searchText = '*';
  public stepDisplayed: StepDisplayedEnum = StepDisplayedEnum.lists;
  public openedList: ListWithItemsCount = null;
  public listOpenCloseState = false;
  private searchType: BookmarksSearchType = BookmarksSearchType.Title;
  private availabilityFilter: SearchAvailabilityFilter = SearchAvailabilityFilter.All;

  public readonly stepDisplayedEnum = StepDisplayedEnum;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
  ) {
  }

  ngOnInit() {
    this.searchWithText('*');
    this.store.dispatch(setSearchFacets());
    this.subscriptions.add(this.store.select(getSearchResults)
    .pipe(filter(Boolean))
    .subscribe(data => {
      this.lists = Object.entries(data).map(([key, value]) => value)
      .sort(this.orderLists);
    }));

    this.subscriptions.add(this.store.select(getOpenedList).subscribe(openedList => {
      this.openedList = openedList;
      this.listOpenCloseState = !!openedList;
    }));
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onOpenCloseAnimation(event: AnimationEvent): void {
    if (event.phaseName === 'start') {
      this.stepDisplayed = StepDisplayedEnum.both;
    } else if (event.toState) {
      this.stepDisplayed = StepDisplayedEnum.singleList;
    } else {
      this.stepDisplayed = StepDisplayedEnum.lists;
      this.store.dispatch(listClosed());
    }
  }

  public closeList() {
    this.listOpenCloseState = false;
  }

  public onSearch(value: string) {
    this.searchText = value;
    this.searchWithText(this.searchText);
  }

  public onSearchTypeChange(value: BookmarksSearchType) {
    this.searchType = value;
    this.searchWithText(this.searchText);
  }

  public onAvailabilityToggle(value: SearchAvailabilityFilter) {
    this.availabilityFilter = value;
    this.searchWithText(this.searchText);
  }

  private searchWithText(searchText: string) {
    const search: BookmarksSearchOptions = {
      searchText,
      searchType: this.searchType,
      availabilityFilter: this.availabilityFilter,
    };
    this.store.dispatch(searchLists({search}));
  }

  private orderLists(a: ListWithItemsCount, b: ListWithItemsCount) {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    }
    return 0;
  }
}
