import { CoverUrl } from 'search/models/search-results';

export interface FinesData {
  totalOutstandingAmount: number;
  data: Fine[];
  actions: FineActions;
}

export interface Fine {
  id: string;
  type: string;
  creationDate: Date | string;
  outstandingAmount: number;
  totalAmount: number;
  description: string;
  resource?: FineResource;
}

export interface FineResource {
  id: string;
  type: string;
  title: string;
  titleDesignator: string;
  materialType: string;
  materialTypeTranslationKey?: string;
  coverUrl: CoverUrl;
  ill: boolean;
}

export interface FineActionsPaypal {
  minimumAmount: number;
  payAll: FinePaymentUrlPaypal;
  paySelected: FinePaymentUrlPaypal;
  paymentNote?: string;
}

export interface FineActionsOther {
  minimumAmount: number;
  payAll: FinePaymentUrlOther;
  paymentNote?: string;
}

export enum FineQueryParamNames {
  fines = 'fines',
  amount = 'amount',
}

export interface FinePaymentUrlPaypal {
  url: string;
  queryParamNames: FineQueryParamNames[];
}

export interface FinePaymentUrlOther {
  url: string;
  buttonText: string;
}

export interface FineError {
  status: number;
  message: string;
}

export enum FinesPaymentStatus {
  OK = 'ok',
  ERROR = 'error',
}

export const FinesPaymentStatuses = [
  FinesPaymentStatus.OK,
  FinesPaymentStatus.ERROR,
];

export type FineActions = FineActionsPaypal | FineActionsOther;
export type FinePaymentUrl = FinePaymentUrlPaypal | FinePaymentUrlOther;
