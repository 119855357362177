<app-bookshelf-template>
  <ng-template bookshelfTemplateTitle>
    <ng-template #listTitle>
      <div class="d-flex flex-nowrap px-1 bookmarks-title" data-automation-id="bookmarks-title">
        <div class="text-truncate d-block">
          {{ openedList.type === listType.forLater ? ('forLater' | translate) : (openedList.name) }}
        </div>
        &nbsp;
        <div class="w-auto">| {{ openedList.itemsCount }}</div>
      </div>
    </ng-template>
    <button class="bookmark__header__button bookmark__header__button--title w-100"
            data-automation-id="bookmarks-header-button"
            *ngIf="lists.length > 1; else listTitle"
            (click)="closeList.emit()">
      <fa-icon class="arrow-icon" [icon]="arrowIconLeft"></fa-icon>
      <ng-container *ngTemplateOutlet="listTitle"></ng-container>
    </button>
  </ng-template>

  <!-- View/Create Showcase (auth ff OFF) -->
  <ng-container *featureToggle="'licenseCustomShowcases'">
    <ng-container *ngIf="!isAuthPatronFlagEnabled">
      <ng-template bookshelfTemplateAction *ngIf="openedList.type === listType.regular">
        <button *ngIf="!openedList.showcaseRef && (hasCreateShowcasePermission$ | async)"
                class="bookmark__header__button"
                data-automation-id="bookmarks-action-button-create-showcase"
                (click)="createShowcase()">
          <fa-icon [icon]="showcaseIcon" [classes]="['icon showcase-icon']"></fa-icon>
          <span class="text" translate>bookmarksCreateShowcase</span>
        </button>
        <button *ngIf="openedList.showcaseRef && (hasViewShowcasePermission$ | async)"
                class="bookmark__header__button"
                data-automation-id="bookmarks-action-button-view-showcase"
                (click)="viewShowcase()">
          <fa-icon [icon]="showcaseIcon" [classes]="['icon showcase-icon']"></fa-icon>
          <span class="text" translate>bookmarksViewShowcase</span>
        </button>
      </ng-template>
    </ng-container>

    <!-- View/Create Showcase -->
    <ng-container *ngIf="isAuthPatronFlagEnabled">
      <ng-template bookshelfTemplateAction *ngIf="openedList.type === listType.regular">
        <ng-container *authorizationDirective="{
                permission: sitePermissions.SHOWCASE_CREATE
              }">
          <button *ngIf="!openedList.showcaseRef"
                  class="bookmark__header__button"
                  data-automation-id="bookmarks-action-button-create-showcase"
                  (click)="createShowcase()">
            <fa-icon [icon]="showcaseIcon" [classes]="['icon showcase-icon']"></fa-icon>
            <span class="text" translate>bookmarksCreateShowcase</span>
          </button>
        </ng-container>
        <ng-container *authorizationDirective="{
                permission: sitePermissions.SHOWCASE_VIEW
              }">
          <button *ngIf="openedList.showcaseRef"
                  class="bookmark__header__button"
                  data-automation-id="bookmarks-action-button-view-showcase"
                  (click)="viewShowcase()">
            <fa-icon [icon]="showcaseIcon" [classes]="['icon showcase-icon']"></fa-icon>
            <span class="text" translate>bookmarksViewShowcase</span>
          </button>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Sort By -->
  <ng-container *ngIf="openedList.items.length">
    <ng-template *ngIf="authorized" bookshelfTemplateSortAction>
      <div class="d-inline-block" ngbDropdown #sortDropdown="ngbDropdown"
           placement="bottom-right bottom left-bottom left auto"
           [autoClose]="'outside'">
        <button class="bookmark__header__button"
                ngbDropdownToggle
                data-automation-id="bookmarks-action-button-sort-by"
                aria-haspopup="menu"
                type="button">
          <ng-container
            [ngTemplateOutlet]="sortLabel"
            [ngTemplateOutletContext]="{field: openedList.sort.field, order: openedList.sort.order, prefix: true}">
          </ng-container>
        </button>
        <div role="menu" ngbDropdownMenu data-automation-id="bookmarks-sort-by-dropdown">
          <button *ngFor="let item of [['date', 'asc'], ['date', 'desc'], ['title', 'asc'], ['title', 'desc']]"
                  (click)="setOpenedListSort(item[0], item[1], sortDropdown)"
                  class="d-block py-2 px-3 w-100 h-auto sort-by-dropdown-button"
                  data-automation-id="bookmarks-sort-by-dropdown-button"
                  ngbDropdownItem
                  role="menuitem">
            <ng-container
              [ngTemplateOutlet]="sortLabel"
              [ngTemplateOutletContext]="{field: item[0], order: item[1]}">
            </ng-container>
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #sortLabel let-field="field" let-order="order" let-prefix="prefix">
            <span class="d-none d-lg-inline">
              <ng-container *ngIf="prefix">{{ 'bookmarksSortBy' | translate }}</ng-container>
              {{ sortTranslateKeys[field][order].full | translate }}
            </span>
      <span class="d-inline d-lg-none">
              <ng-container *ngIf="prefix">{{ 'bookmarksSortByShort' | translate }}</ng-container>
        {{ sortTranslateKeys[field][order].short | translate }}
            </span>
    </ng-template>

    <!-- Select All -->
    <ng-template bookshelfTemplateAction>
      <button class="bookmark__header__button"
              data-automation-id="bookmarks-action-button-toggle-select-all"
              (click)="setAllItemsSelection(openedList.id, !isAllSelected)"
              [attr.aria-pressed]="isAllSelected">
              <span class="icon" aria-hidden="true"
                    [ngClass]="isAllSelected ? 'fas fa-check-square' :  'far fa-square'"></span>
        <span translate class="text">bookmarksSelectAll</span>
      </button>
    </ng-template>

    <!-- Selection Counter -->
    <ng-template bookshelfTemplateAction>
      <div aria-live="polite" role="status"
           class="d-inline-flex align-items-center h-100 p-2"
           data-automation-id="bookmarks-selection-counter">
        <strong class="text-nowrap">
          {{ 'bookmarksItemsCountSelected' | translate: {itemsCount: itemsSelectedCount} }}:
        </strong>
      </div>
    </ng-template>

    <!-- Place Holds -->
    <ng-container *customerFeatureToggle="CustomerFeature.BulkHolds">
      <ng-template bookshelfTemplateAction>
        <button class="bookmark__header__button"
                data-automation-id="bookmarks-action-button-place-holds"
                [disabled]="!itemsSelectedCount"
                (click)="bulkPlaceHolds($event)">
          <fa-icon [icon]="placeHoldsIcon" [classes]="['icon place-holds-icon mr-1']"></fa-icon>
          <span translate class="text">bookmarksPlaceHolds</span>
        </button>
      </ng-template>
    </ng-container>

    <!-- Add To Lists -->
    <ng-template *ngIf="authorized" bookshelfTemplateAction>
      <app-select-list-button [buttonClass]="'bookmark__header__button'"
                              [lists]="lists"
                              [listItemEntities]="itemsEntitySelected"
                              [listIdsSelected]="[openedList.id]"
                              [disabled]="!itemsSelectedCount"
                              [multiselect]="true"
                              [canCreateNew]="true"
                              [confirmDelete]="true"
                              data-automation-id="bookmarks-action-add-to-list">
        <span class="fas fa-folder-plus icon d-inline-flex" aria-hidden="true"></span>
        <span class="text" translate>bookmarksAddToLists</span>
      </app-select-list-button>
    </ng-template>

    <!-- Remove From List -->
    <ng-template bookshelfTemplateAction>
      <app-bookshelf-remove-selected-from-list-button [itemsSelectedCount]="itemsSelectedCount"
                                                      [buttonClass]="'bookmark__header__button'"
                                                      (remove)="removeSelectedFromList()">
      </app-bookshelf-remove-selected-from-list-button>
    </ng-template>
  </ng-container>

  <!-- Sign In -->
  <ng-template bookshelfTemplateSubheader *ngIf="!authorized">
    <div class="d-flex align-items-center" data-automation-id="bookmarks-subheader">
      <svg class="bookmark__subtitle__timer-sand-icon flex-shrink-0 mr-sm-3" viewBox="0 0 24 24">
        <path fill="currentColor"
              d="M6,2H18V8H18V8L14,12L18,16V16H18V22H6V16H6V16L10,12L6,8V8H6V2M16,16.5L12,12.5L8,16.5V20H16V16.5M12,11.5L16,7.5V4H8V7.5L12,11.5M10,6H14V6.75L12,8.75L10,6.75V6Z"/>
      </svg>
      <div class="mx-3">
        <p class="m-0" data-automation-id="bookmarks-subheader-disappear" translate>
          listYourBookmarksWillDisappear
        </p>
        <p class="m-0" data-automation-id="bookmarks-subheader-to-keep" translate>listToKeepSignIn</p>
      </div>
      <button class="ins-buttons button-primary flint ml-sm-3"
              data-automation-id="bookmarks-sign-in-button"
              (click)="logIn($event)"
              translate>
        signIn
      </button>
    </div>
  </ng-template>

  <!-- Items List -->
  <ng-template bookshelfTemplateBody="ps-short" let-psScroll="psScroll">
    <div
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollDisabled]="!psScroll || !authorized || openedList.itemsLoadingState.loading || allListItemsLoaded"
      [infiniteScrollThrottle]="50"
      [infiniteScrollContainer]="psScroll">
      <app-searchable-bookmarks-search-bar
        (onAvailabilityToggle)="onAvailabilityToggle($event)"
        (onSearchTypeSelect)="onSearchTypeChange($event)"
        (onSearch)="onSearch($event)"
      ></app-searchable-bookmarks-search-bar>
      <app-bookshelf-items-list [bookshelfItems]="openedList.items"
                                [listId]="openedList.id"
                                [ariaLabel]="'bookmarksListItemsListTitle' | translate"
                                (onToggleItem)="onToggleItem(openedList.id, $event)">
      </app-bookshelf-items-list>
      <ng-container [ngTemplateOutlet]="itemsLoading"
                    [ngTemplateOutletContext]="{state: openedList.itemsLoadingState}"></ng-container>
    </div>
  </ng-template>
</app-bookshelf-template>
