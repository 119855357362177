import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import {
  faAddressCard,
  faBellExclamation,
  faClock,
  faEnvelope,
  faHomeAlt,
  faMobileAndroid,
  faUser
} from '@fortawesome/pro-regular-svg-icons';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { updateReadingHistoryOptIn, updateReadingHistoryOptOut, } from 'app/reading-history/actions/reading-history.actions';
import { getReadingHistoryStatusState, ReadingHistoryState } from 'app/reading-history/reducers/reading-history.reducer';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { merge, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { areEqualDeeply } from 'shared/utils/are-equal-deeply';
import { notBlankValidator, phoneNumberValidator } from 'shared/utils/validators';
import { resetPatronPasscodeState } from 'user/actions/user-profile.actions';
import { ChangePasscodeDialogComponent } from 'user/components/account/profile/change-passcode-dialog/change-passcode-dialog.component';
import { NotificationPreferenceService } from 'user/components/account/profile/notification-preference.service';
// eslint-disable-next-line max-len
import {
  ProfileFormFieldInputSelectExtendedComponent,
  SelectValueExtended,
} from 'user/components/account/profile/profile-form-field-input-select-extended/profile-form-field-input-select-extended.component';
import { requiredIfSiblingHasData } from 'user/components/bookshelf/helpers/required-if-sibling-has-data';
import {
  Carrier,
  FieldsConfiguration,
  HoldSettingType,
  MobilePhone,
  ProfileUpdate,
  ProfileUpdateServerError,
  User
} from 'user/models/user';
import { getEnableReadingHistorySettingStatus, getPasscodeUpdateStatus, ProfileUpdateState, UserState } from 'user/reducers/user.reducer';
import { PhysicalLocation, PickupArea } from '../../../../../models/locations';
import { DictionariesService } from '../../../../../services/dictionaries.service';
import { SelectValue } from '../profile-form-field-input-select/profile-form-field-input-select.component';
import { TranslateService } from '@ngx-translate/core';
import { IlsType } from 'shared/models/ilsType';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { loadPickupArea } from 'core/actions/library-info.actions';
import { getPickupArea } from 'core/reducers/library-info.reducer';

type ServerError = 'unknown' | 'forbidden' | 'validation' | null;
type ServerFieldsError = { [key in keyof ProfileUpdate]?: ProfileUpdateServerError };

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() public user: User;
  @Input() public isEditing = false;
  @Input() public isCodeChanging = false;
  @Input() public ilsType: IlsType;
  @Input() public updateState$: Observable<ProfileUpdateState>;
  @Input() public touchForm$: Observable<undefined>;
  // null means value is not changed or form is not valid
  @Output() public readonly formValueChange = new EventEmitter<ProfileUpdate | null>();
  @Output() codeChanging = new EventEmitter<boolean>();
  @ViewChild(ProfileFormFieldInputSelectExtendedComponent) public selectExtended: ProfileFormFieldInputSelectExtendedComponent;

  public form: UntypedFormGroup;
  public isLoading = false;
  public serverError: ServerError = null;
  public serverFieldsError: ServerFieldsError = {};
  public keepReadingHistoryStatus$ = this.store.select(getReadingHistoryStatusState);
  public keepReadingHistoryStatus: boolean;
  public isReadingHistoryEnabled: boolean;
  public isEnableReadingHistorySettingStatus$ = this.store.select(getEnableReadingHistorySettingStatus);
  public isEnableReadingHistorySettingStatus: boolean;
  public passcodeUpdateSuccess: boolean;
  public notificationPreferenceOptions: Array<SelectValue | SelectValueExtended>;
  public notificationPreferenceEnabled: boolean;
  public hidePreferredLocations: boolean;
  public readonly IlsType = IlsType;

  public usePreselectedPhone: boolean;
  public carriers: Carrier[];
  public readonly disableCheckbox = true;

  public readonly userIcon = faUser;
  public readonly emailIcon = faEnvelope;
  public readonly mobileIcon = faMobileAndroid;
  public readonly homeIcon = faHomeAlt;
  public readonly cardIcon = faAddressCard;
  public readonly expirationDateIcon = faClock;
  public readonly passcodeIcon = faLockAlt;
  public readonly editIcon = faEdit;
  public readonly notificationIcon = faBellExclamation;
  public pickupLocations: SelectValue[];
  public pickupAreas: SelectValue[];
  public selectedPickupLocationCode: string;
  public selectedArea: SelectValue;
  public isPickupAreaFeatureEnabled: boolean;

  private formSubscription?: Subscription;
  private notificationOptionsSubscription?: Subscription;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly dictionaryService: DictionariesService,
    private readonly store: Store<ReadingHistoryState|UserState>,
    private readonly featureToggleService: FeatureToggleService,
    private readonly modal: NgbModal,
    private readonly notificationPrefService: NotificationPreferenceService,
    private readonly translateService: TranslateService,
    private readonly cd: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.isPickupAreaFeatureEnabled = this.featureToggleService.getToggles()['VDIS-30375_2024-12-31_pickup-area'];
    this.isReadingHistoryEnabled = this.featureToggleService.getToggles().licenseReadingHistory;
    this.usePreselectedPhone = this.user.profileConfiguration.notificationConfiguration.usePreselectedPhone;
    this.notificationPreferenceEnabled = this.user.profileConfiguration.notificationConfiguration.enabled;
    this.carriers = this.user.profileConfiguration.carriers || [];
    this.hidePreferredLocations = this.user.profileConfiguration.holdSetting === HoldSettingType.HOME;
    this.buildForm();
    this.subscriptions.add(
      this.touchForm$.subscribe(() => {
        this.form.markAllAsTouched();
      }),
    );

    this.subscriptions.add(
      this.store.select(getPickupArea).pipe(
        map((pickupAreas: PickupArea[]) =>
         ( pickupAreas ?? []).map((item) => ({
          id: item.code,
          value: item.description,
          parentCode: item.parentCode
        })))
      ).subscribe((mappedPickupAreas) => {
          this.pickupAreas = mappedPickupAreas;
          this.selectedArea = this.pickupAreas?.find((el) => {
            return el.id === this.user.preferredPickupLocationAreaCode
            && el.parentCode === this.user.preferredPickupLocationCode
            || null;
          });
          this.cd.markForCheck();
      })
    );

    this.subscriptions.add(
      this.keepReadingHistoryStatus$.subscribe((status) => {
        this.keepReadingHistoryStatus = status;
      }),
    );

    this.subscriptions.add(
      this.isEnableReadingHistorySettingStatus$.subscribe((status) => {
        this.isEnableReadingHistorySettingStatus = status;
      }),
    );

    this.subscriptions.add(
      this.store.select(getPasscodeUpdateStatus)
      .subscribe((status) => {
        this.passcodeUpdateSuccess = status.success;
      }),
    );

    this.subscriptions.add(
      this.updateState$.subscribe((updateState) => {
        this.isLoading = updateState.loading;

        this.resetServerError();
        if (updateState.error) {
          if (updateState.error.status === 400 && updateState.error.fields) {
            this.serverError = 'validation';
          } else if (updateState.error.status === 403) {
            this.serverError = 'forbidden';
          } else if (updateState.error.status === 500 && updateState.error.fields) {
            this.makeServerErrorFieldsUpdateObject(updateState.error.fields, ProfileUpdateServerError.update);
          } else {
            this.serverError = 'unknown';
          }
        }
      }),
    );

    this.subscriptions.add(
      this.dictionaryService.getPickupLocations().pipe(
        map((pickupLocations: PhysicalLocation[]) => pickupLocations.map((pickupLocation: PhysicalLocation) => ({
          id: pickupLocation.code,
          value: this.translateService.instant(`location.${pickupLocation.code}`)
        })))
      ).subscribe((pickupLocations: SelectValue[]) => {
        this.pickupLocations = pickupLocations;
      }),
    );
  }

  public onChange(): void {
    if (this.keepReadingHistoryStatus) {
      this.store.dispatch(updateReadingHistoryOptOut());
    } else {
      this.store.dispatch(updateReadingHistoryOptIn());
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.isEditing
      && (
        (!changes.isEditing.previousValue && changes.isEditing.currentValue)
        || (changes.isEditing.previousValue && !changes.isEditing.currentValue)
      )
    ) {
      // rebuild form on open and close
      this.buildForm();
      this.resetServerError();
    }
  }

  public ngAfterViewInit() {
    // programmatically set select value of the child component
    if (!this.notificationPrefService.isCurrentOptionSupported(this.user)) {
      this.setNotificationPreferenceToNone();
    } else {
      const currentOption = this.notificationPrefService.getSelectedValue(this.notificationPreference.value);
      this.selectExtended?.select.setValue(currentOption);
    }
  }

  public ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  public addEmailField(): void {
    this.emails.push(ProfileFormComponent.makeEmailFormControl());
  }

  public addAddressField() {
    this.addresses.push(new UntypedFormControl(null));
  }

  public addMobilePhoneField(): void {
    this.mobilePhones.push(ProfileFormComponent.makePhoneFormControl());
  }

  public addMobilePhoneFormGroup(): void {
    this.usePreselectedPhone ?
      this.mobilePhones.push(ProfileFormComponent.makeMobilePhoneWithCarrier()) :
      this.mobilePhones.push(ProfileFormComponent.makeMobilePhonePlain());
  }

  public addHomePhoneField(): void {
    this.homePhones.push(ProfileFormComponent.makePhoneFormControl());
  }

  public openChangePasscodeDialog(): void {
    const modalRef = this.modal.open(ChangePasscodeDialogComponent, { backdrop: 'static', centered: true });
    modalRef.componentInstance.passcodePolicyMessage = this.user.profileConfiguration.passcodePolicyMessage;

    modalRef.result.finally(() => {});
  }

  public resetPasscodeUpdateState(): void {
    this.store.dispatch(resetPatronPasscodeState());
  }

  private static makeEmailFormControl(value?: string): UntypedFormControl {
    return new UntypedFormControl(value || null, [Validators.email]);
  }

  private static makePhoneFormControl(value?: string | MobilePhone, validators?: ValidatorFn): UntypedFormControl {
    return new UntypedFormControl(value || null, validators ? [validators, phoneNumberValidator] : [phoneNumberValidator]);
  }

  private static makeMobilePhonePlain(value?: MobilePhone): UntypedFormGroup {
    return new UntypedFormGroup({
      phone: ProfileFormComponent.makePhoneFormControl(value?.phone || ''),
    });
  };

  private static makeMobilePhoneWithCarrier(value?: MobilePhone): UntypedFormGroup {
    return new UntypedFormGroup({
      phone: ProfileFormComponent.makePhoneFormControl(value?.phone || '', requiredIfSiblingHasData('carrierId')),
      carrierId: new UntypedFormControl(value?.carrierId || null, requiredIfSiblingHasData('phone')),
    });
  };

  private static filterNonEmptyChangedFields(value: ProfileUpdate): ProfileUpdate {
    return {
      addresses: value.addresses?.filter((address) => !!address) || [],
      emails: value.emails?.filter((email) => !!email) || [],
      homeLibraryCode: value.homeLibraryCode || '',
      preferredPickupLocationCode: value.preferredPickupLocationCode || '',
      preferredPickupLocationAreaCode: value.preferredPickupLocationAreaCode || '',
      homePhones: value.homePhones?.filter((homePhone) => !!homePhone) || [],
      mobilePhones: value.mobilePhones?.filter((mobilePhone) => !!mobilePhone?.phone) || [],
      name: value.name || '',
      nickname: value.nickname || '',
      passcode: value.passcode || '',
      notificationPreference: value.notificationPreference || null
    };
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      name: this.formBuilder.control(this.user.name, [notBlankValidator]),
      nickname: [this.user.nickname, [Validators.maxLength(25)]],
      emails: this.formBuilder.array(
        this.user.emails.map((email) => ProfileFormComponent.makeEmailFormControl(email)),
      ),
      addresses: this.formBuilder.array(this.user.addresses),
      mobilePhones: this.formBuilder.array(
        this.user.mobilePhones.map((phone) => {
          return this.usePreselectedPhone ?
            ProfileFormComponent.makeMobilePhoneWithCarrier(phone as MobilePhone) :
            ProfileFormComponent.makeMobilePhonePlain(phone);
        }),
      ),
      homePhones: this.formBuilder.array(
        this.user.homePhones.map((phone) => ProfileFormComponent.makePhoneFormControl(phone)),
      ),
      homeLibraryCode: [this.user.homeLibraryCode],
      preferredPickupLocationCode: [this.user.preferredPickupLocationCode],
      preferredPickupLocationAreaCode: [this.user.preferredPickupLocationAreaCode],
      registeredBranch: [this.user.registeredBranch],
      cardNumber: this.formBuilder.control({value: this.user.cardNumber, disabled: true}),
      expirationDate: this.formBuilder.control({value: this.user.expirationDate, disabled: true}),
      passcode: this.formBuilder.control({value: '********', disabled: true}),
      notificationPreference: this.getNotificationPreferenceFormGroup(),
    });

    this.notificationPreferenceOptions = this.notificationPrefService.translateNotificationOptions(this.getNotificationPreferenceOptions());
    this.disableNotEditableFields(this.user.profileConfiguration.fieldsConfiguration);

    const originalValue = ProfileFormComponent.filterNonEmptyChangedFields(this.form.value);

    this.formSubscription?.unsubscribe();
    this.formSubscription = this.form.valueChanges
    .pipe(distinctUntilChanged())
    .subscribe((value) => {
      if (this.form.status !== 'VALID') {
        this.formValueChange.emit(null);
        return;
      }
      const filteredValue = ProfileFormComponent.filterNonEmptyChangedFields(value);
      const changes = this.calculateUpdate(filteredValue, originalValue);
      this.handlePickupArea(changes);
      this.formValueChange.emit(areEqualDeeply(changes, {}) ? null : changes);
    });

    this.notificationOptionsSubscription?.unsubscribe();
    this.notificationOptionsSubscription = merge(this.mobilePhones.valueChanges, this.homePhones.valueChanges, this.emails.valueChanges)
    .pipe(debounceTime(500))
    .subscribe(() => {
      this.notificationPreferenceOptions = this.notificationPrefService.translateNotificationOptions(this.getNotificationPreferenceOptions());
      if (this.isCurrentOptionDisabled()) {
        this.setNotificationPreferenceToNone();
      }
    });
  }

  private handlePickupArea(changes: ProfileUpdate): void {
    if (changes.preferredPickupLocationCode && this.ilsType === IlsType.polaris && this.isPickupAreaFeatureEnabled) {
      this.isCodeChanging = true;
      this.codeChanging.emit(this.isCodeChanging);
      this.store.dispatch(loadPickupArea({ pickupLocationCode: changes.preferredPickupLocationCode }));
    }
  }

  get emails(): UntypedFormArray {
    return this.form.get('emails') as UntypedFormArray;
  }

  get addresses(): UntypedFormArray {
    return this.form.get('addresses') as UntypedFormArray;
  }

  get mobilePhones(): UntypedFormArray {
    return this.form.get('mobilePhones') as UntypedFormArray;
  }

  get homePhones(): UntypedFormArray {
    return this.form.get('homePhones') as UntypedFormArray;
  }

  get homeLibraryCode(): UntypedFormControl {
    return this.form.get('homeLibraryCode') as UntypedFormControl;
  }
  get preferredPickupLocationCode(): UntypedFormControl {
    return this.form.get('preferredPickupLocationCode') as UntypedFormControl;
  }

  get preferredPickupLocationAreaCode(): UntypedFormControl {
    return this.form.get('preferredPickupLocationAreaCode') as UntypedFormControl;
  }

  get passcode(): UntypedFormControl {
    return this.form.get('passcode') as UntypedFormControl;
  }

  get notificationPreference(): UntypedFormGroup {
    return this.form.get('notificationPreference') as UntypedFormGroup;
  }

  get channel(): UntypedFormControl {
    return this.notificationPreference.get('channel') as UntypedFormControl;
  }

  private disableNotEditableFields(fieldsConfiguration: FieldsConfiguration): void {
    Object.keys(fieldsConfiguration).forEach((key: keyof FieldsConfiguration) => {
      if (!fieldsConfiguration[key] && key !== 'enableReadingHistory') {
        this.form.get(key).disable();
      }
    });
  }

  private calculateUpdate(filteredValue: ProfileUpdate, originalValue: ProfileUpdate): ProfileUpdate {
    return Object.keys(filteredValue).reduce((result, key: keyof ProfileUpdate) => {
      if (!areEqualDeeply(filteredValue[key], originalValue[key])) {
        result = {
          ...result,
          [key]: filteredValue[key],
        };
      }

      return result;
    }, {} as ProfileUpdate);
  }

  private makeServerErrorFieldsUpdateObject(fields: (keyof ProfileUpdate)[], serverError: ProfileUpdateServerError): void {
    this.serverFieldsError = fields.reduce((result, field) => {
      result[field] = serverError;

      return result;
    }, {} as ServerFieldsError);
  }

  private resetServerError(): void {
    this.serverError = null;
    this.serverFieldsError = {};
  }

  private getNotificationPreferenceOptions(): Array<SelectValue | SelectValueExtended> {
    const supportedTypes = this.user.profileConfiguration.notificationConfiguration?.supportedTypes;

    return this.usePreselectedPhone ?
      this.notificationPrefService.getNotificationOptionsExtended(supportedTypes, this.form) :
      this.notificationPrefService.getNotificationOptions(supportedTypes, this.form);
  }

  private setNotificationPreferenceToNone(): void {
    this.notificationPreference.patchValue({channel: 'none', preselectedPhone: null});
    this.selectExtended?.select.setValue('none');
  }

  private getNotificationPreferenceFormGroup(): UntypedFormGroup {
    const formValue = this.notificationPrefService.isCurrentOptionSupported(this.user) ?
      this.user.notificationPreference :
      this.notificationPrefService.notificationPreferenceNone;

    return this.usePreselectedPhone ?
      this.formBuilder.group({
        channel: {value: formValue.channel, disabled: !this.notificationPreferenceEnabled},
        preselectedPhone: formValue.preselectedPhone,
      }) :
      this.formBuilder.group({
        channel: {value: formValue.channel, disabled: !this.notificationPreferenceEnabled},
      });
  }

  private isCurrentOptionDisabled(): boolean {
    const selectedValue = this.notificationPrefService.getSelectedValue(this.notificationPreference.value);
    const currentOption = this.notificationPreferenceOptions.find((type) => type.id === selectedValue);
    return currentOption ? currentOption.disabled : true;
  }
}
