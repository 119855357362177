<div class="availability-card">
  <div class="title-container">
    <div class="label-container">
      <p class="label">Bookmarked items available now</p>
      <img class="icon-online" src="../../../../assets/icon-online.svg" aria-hidden="true"/>
    </div>
    <div class="link-container">
      <a class="link">
        View all available bookmarks
        <fa-icon [icon]="linkIcon" [classes]="['icon place-holds-icon mr-1']"></fa-icon>
      </a>
    </div>
  </div>
  <div class="options">
    <!-- Select All -->
    <button class="bookmark__header__button"
            data-automation-id="bookmarks-search-preview-action-button-toggle-select-all"
            (click)="setAllItemsSelection(!isAllSelected)"
            [attr.aria-pressed]="isAllSelected">
            <span class="icon" aria-hidden="true"
                  [ngClass]="isAllSelected ? 'fas fa-check-square' :  'far fa-square'"></span>
      <span translate class="text">bookmarksSelectAll</span>
    </button>

    <!-- Selection Counter -->
    <div aria-live="polite" role="status"
         class="d-inline-flex align-items-center h-100 p-2"
         data-automation-id="bookmarks-search-preview-selection-counter">
      <strong class="text-nowrap">
        {{ 'bookmarksItemsCountSelected' | translate: {itemsCount: itemsSelectedCount} }}:
      </strong>
    </div>

    <!-- Place Holds -->
    <ng-container *customerFeatureToggle="CustomerFeature.BulkHolds">
      <button class="bookmark__header__button"
              data-automation-id="bookmarks-search-preview-action-button-place-holds"
              [disabled]="!itemsSelectedCount"
              (click)="bulkPlaceHolds($event)">
        <fa-icon [icon]="placeHoldsIcon" [classes]="['icon place-holds-icon mr-1']"></fa-icon>
        <span translate class="text">bookmarksPlaceHolds</span>
      </button>
    </ng-container>
  </div>

  <div class="items-container">
    <div class="item" *ngFor="let item of list; index as i; last as isLast">
      <div class="mb-1" *ngIf="true">
        <ins-bs-checkbox
          data-automation-id="bookmarks-search-preview-bulk-select-checkbox"
          (change)="onItemSelectChange(item.id, $event.checked)"
          [checked]="item.selected"
          [ariaLabelledby]="ariaTitleId"
          [containerClass]="['mr-3']">
        </ins-bs-checkbox>
      </div>
      <div class="cover-wrapper">
        <div class="cover-container pr-3">
          <app-cover
            [config]="item.entity.coverConfig"
            [title]="item.entity.title"
            [author]="item.entity.primaryAgent?.label"
            data-automation-id="bookmarks-search-preview-card-image"
            size="large">
          </app-cover>
        </div>
        <div class="details">
          <p class="title">{{ item.entity.title }}</p>
          <p class="author">{{ item.entity.primaryAgent?.label }}</p>
        </div>
        <div class="availability-container">
          <div class="availability-icon" *ngFor="let icon of item.icons">
            <img src="{{ icon.url }}" [style.filter]="icon.filter" alt="{{ icon.label }}"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
