import { Component, OnDestroy, OnInit } from '@angular/core';
import { faArrowRight, faCartShoppingFast } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';
import { tryToBulkHoldSelectedItems } from '../../../bulk-select/actions/bulk-select.actions';
import { BulkFeatureKey } from '../../../bulk-select/enums/bulk-feature-key';
import { CustomerFeature } from '../../../customer-integration/customer-integration';
import { AvailabilityStatus, FormatGroup, MaterialTab } from '../../../entity/models/entity';
import { DictionaryItem, DictionaryTypes } from '../../../models/dictionaries';
import { AvailabilityStatusToFilterMap, MATERIAL_ICONS } from '../../../models/material-icons';
import { DictionariesService } from '../../../services/dictionaries.service';
import { ListItem } from '../../models/list';
import { getSearchResultsPreview } from '../../reducers/list.reducer';

export interface Icon {
  url: string,
  filter: string,
  label: string
}
export type ListItemWithIcons = ListItem & {icons?: Icon[]}

@Component({
  selector: 'app-searchable-bookmarks-result-list',
  templateUrl: './searchable-bookmarks-results-preview.component.html',
  styleUrls: ['./searchable-bookmarks-results-preview.component.scss']
})
export class SearchableBookmarksResultsPreviewComponent implements OnInit, OnDestroy {
  public list: ListItemWithIcons[] = [];
  public itemsSelectedCount: number;
  public isAllSelected: boolean;
  public placeHoldsIcon = faCartShoppingFast;
  public linkIcon = faArrowRight;
  public bulkFeatureKey = BulkFeatureKey.LIBRARY_LIST;
  public readonly CustomerFeature = CustomerFeature;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly dictionariesService: DictionariesService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.store.select(getSearchResultsPreview)
    .pipe(filter(Boolean))
    .subscribe(list => {
      this.list = list.map(item => {
        (item as ListItemWithIcons).icons = this.getIcons((item.entity.sourceEntity as FormatGroup).materialTabs);
        return item;
      });
    }));

    this.itemsSelectedCount = this.list.filter(item => item.selected).length;
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public setAllItemsSelection(selected: boolean) {
    this.isAllSelected = !this.isAllSelected;
    this.list.forEach(item => item.selected = this.isAllSelected);
  }

  public bulkPlaceHolds(event: Event) {
    event.stopPropagation();
    const items = this.list.filter(item => item.selected).map((item) => item.entity.sourceEntity);
    this.store.dispatch(tryToBulkHoldSelectedItems({bulkFeatureKey: this.bulkFeatureKey, items}));
  }

  public onItemSelectChange(itemId: string, checked: boolean) {
    const item = this.list.find(item => item.id === itemId);
    item.selected = checked;
    if (this.isAllSelected && !checked) {
      this.isAllSelected = false;
    }
    if (!this.list.find(item => !item.selected)) {
      this.isAllSelected = true;
    }
    this.itemsSelectedCount = this.list.filter(item => item.selected).length;
  }

  private getIcons(materialTabs: MaterialTab[]) {
    const icons: Icon[] = [];
    materialTabs.forEach(m => {
      const code = m.materialTypes?.[0];
      const item: DictionaryItem = this.dictionariesService.getDictionaryItemByCode(DictionaryTypes.MATERIAL_TYPES, code);
      const label = item?.icon ?? 'Default other';
      const url = MATERIAL_ICONS[label];
      const availabilityStatus = m.availability.status.general ?? AvailabilityStatus.UNAVAILABLE;
      const filter = AvailabilityStatusToFilterMap[availabilityStatus];
      icons.push({url, filter, label});
    });
    return icons;
  }
}
