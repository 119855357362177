import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  loadLocationInfo,
  loadLocationInfoFailure,
  loadLocationInfoSuccess,
  loadPickupArea,
  loadPickupAreaSuccess,
  loadVisibleLocationsSuccess,
  setDefaultLibraryCode,
} from 'core/actions/library-info.actions';
import { LocationInfo } from 'shared/models/library-info';
import { LocationWithName, PickupArea } from '../../models/locations';

export interface LibraryInfoState {
  visibleLocations: LocationWithName[];
  locationInfo: LocationInfo;
  locationInfoLoading: boolean;
  defaultLibraryCode: string;
  availablePickupArea: PickupArea[];
}

export const initialState: LibraryInfoState = {
  visibleLocations: null,
  locationInfo: null,
  locationInfoLoading: false,
  defaultLibraryCode: null,
  availablePickupArea: null,
};

const _reducer = createReducer(
  initialState,
  on(loadVisibleLocationsSuccess, (state, {visibleLocations}) => ({
    ...state,
    visibleLocations,
  })),
  on(loadPickupArea, (state) => ({
    ...state,
  })),
  on(loadPickupAreaSuccess, (state, {pickupArea}) => ({
    ...state,
    availablePickupArea: pickupArea,
  })),
  on(loadLocationInfo, (state) => ({
    ...state,
    locationInfoLoading: true,
  })),
  on(loadLocationInfoSuccess, (state, {locationInfo}) => ({
    ...state,
    locationInfo,
    locationInfoLoading: false,
  })),
  on(loadLocationInfoFailure, (state) => ({
    ...state,
    locationInfoLoading: false,
  })),
  on(setDefaultLibraryCode, (state, {defaultLibraryCode}) => ({
    ...state,
    defaultLibraryCode,
  }))
);

export function reducer(state: LibraryInfoState | undefined, action: Action) {
  return _reducer(state, action);
}

export const featureKey = 'libraryInfo';

export const getLibraryListState = createFeatureSelector<LibraryInfoState>(featureKey);

export const getVisibleLocations = createSelector(getLibraryListState, (state: LibraryInfoState): LocationWithName[] => {
  return state.visibleLocations;
});

export const getSelectedLocationInfo = createSelector(getLibraryListState, (state: LibraryInfoState): LocationInfo => {
  return state.locationInfo;
});

export const getPickupArea = createSelector(getLibraryListState, (state: LibraryInfoState): PickupArea[] => {
  return state.availablePickupArea;
});

export const getDefaultLibraryCode = createSelector(getLibraryListState, (state: LibraryInfoState): string => {
  return state.defaultLibraryCode;
});

export const getLocationInfoLoading = createSelector(getLibraryListState, (state: LibraryInfoState): boolean => {
  return state.locationInfoLoading;
});
