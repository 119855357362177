import { AvailabilityStatus } from '../entity/models/entity';

const MATERIAL_ICONS_BASE_URL = `${API_URL}/discover-admin-path/material-icons`;

export const MATERIAL_ICONS: {[key: string]: string} = {
  'Default other':  `${MATERIAL_ICONS_BASE_URL}/default-other.svg`,
  'Activity pass':  `${MATERIAL_ICONS_BASE_URL}/activity-pass.svg`,
  'Audiobook':  `${MATERIAL_ICONS_BASE_URL}/audiobook.svg`,
  'Blu-Ray':  `${MATERIAL_ICONS_BASE_URL}/blu-ray.svg`,
  'Book':  `${MATERIAL_ICONS_BASE_URL}/book.svg`,
  'Book kit':  `${MATERIAL_ICONS_BASE_URL}/book-kit.svg`,
  'Braille':  `${MATERIAL_ICONS_BASE_URL}/braille.svg`,
  'Dvd':  `${MATERIAL_ICONS_BASE_URL}/dvd.svg`,
  'eGoverment document':  `${MATERIAL_ICONS_BASE_URL}/e-gov-doc.svg`,
  'eResource':  `${MATERIAL_ICONS_BASE_URL}/e-resource.svg`,
  'eBook':  `${MATERIAL_ICONS_BASE_URL}/ebook.svg`,
  'Equipment':  `${MATERIAL_ICONS_BASE_URL}/equipment.svg`,
  'Game':  `${MATERIAL_ICONS_BASE_URL}/game.svg`,
  'Goverment document':  `${MATERIAL_ICONS_BASE_URL}/gov-doc.svg`,
  'Graphic Novel':  `${MATERIAL_ICONS_BASE_URL}/graphic-novel.svg`,
  'Laptop':  `${MATERIAL_ICONS_BASE_URL}/laptop.svg`,
  'Large Type':  `${MATERIAL_ICONS_BASE_URL}/large-type.svg`,
  'Magazine':  `${MATERIAL_ICONS_BASE_URL}/magazine.svg`,
  'Manuscript':  `${MATERIAL_ICONS_BASE_URL}/manuscript.svg`,
  'Map':  `${MATERIAL_ICONS_BASE_URL}/map.svg`,
  'Mobile Device':  `${MATERIAL_ICONS_BASE_URL}/mobile-device.svg`,
  'Music Cassette':  `${MATERIAL_ICONS_BASE_URL}/music-cassette.svg`,
  'Music CD':  `${MATERIAL_ICONS_BASE_URL}/music-cd.svg`,
  'Newspaper':  `${MATERIAL_ICONS_BASE_URL}/newspaper.svg`,
  'Nintendo':  `${MATERIAL_ICONS_BASE_URL}/nintendo.svg`,
  'Photograph':  `${MATERIAL_ICONS_BASE_URL}/photograph.svg`,
  'Picture Book':  `${MATERIAL_ICONS_BASE_URL}/picture-book.svg`,
  'Playstation':  `${MATERIAL_ICONS_BASE_URL}/playstation.svg`,
  'Puzzle':  `${MATERIAL_ICONS_BASE_URL}/puzzle.svg`,
  'Seeds':  `${MATERIAL_ICONS_BASE_URL}/seeds.svg`,
  'Sheet music':  `${MATERIAL_ICONS_BASE_URL}/sheet-music.svg`,
  'Software':  `${MATERIAL_ICONS_BASE_URL}/software.svg`,
  'Stream video':  `${MATERIAL_ICONS_BASE_URL}/stream-video.svg`,
  'Streaming Music':  `${MATERIAL_ICONS_BASE_URL}/streaming-music.svg`,
  'Talking Book':  `${MATERIAL_ICONS_BASE_URL}/talking-book.svg`,
  'VHS':  `${MATERIAL_ICONS_BASE_URL}/vhs.svg`,
  'Video Game':  `${MATERIAL_ICONS_BASE_URL}/video-game.svg`,
  'Vinyl':  `${MATERIAL_ICONS_BASE_URL}/vinyl.svg`,
  'Book + Audio':  `${MATERIAL_ICONS_BASE_URL}/vox.svg`,
  'Website':  `${MATERIAL_ICONS_BASE_URL}/website.svg`,
  'Wifi':  `${MATERIAL_ICONS_BASE_URL}/wifi.svg`,
  'Xbox':  `${MATERIAL_ICONS_BASE_URL}/xbox.svg`,
  'Museum':  `${MATERIAL_ICONS_BASE_URL}/museum.svg`,
  'Toy':  `${MATERIAL_ICONS_BASE_URL}/toy.svg`,
  'Non Musical recording':  `${MATERIAL_ICONS_BASE_URL}/non-musical-recording.svg`,
  'Videorecording':  `${MATERIAL_ICONS_BASE_URL}/videorecording.svg`,
  'Bake Equipment':  `${MATERIAL_ICONS_BASE_URL}/bake-equipment.svg`,
  'Equipment Kit':  `${MATERIAL_ICONS_BASE_URL}/equipment-kit.svg`,
  'Medical':  `${MATERIAL_ICONS_BASE_URL}/medical.svg`,
  'Microfilm':  `${MATERIAL_ICONS_BASE_URL}/microfilm.svg`,
  'Microform':  `${MATERIAL_ICONS_BASE_URL}/microform.svg`,
  'Atlas':  `${MATERIAL_ICONS_BASE_URL}/atlas.svg`,
  'eAudiobook':  `${MATERIAL_ICONS_BASE_URL}/e-audiobook.svg`,
  'eGraphic Novel':  `${MATERIAL_ICONS_BASE_URL}/e-graphic-novel.svg`,
  'eMagazine':  `${MATERIAL_ICONS_BASE_URL}/e-magazine.svg`,
  'eEquipment':  `${MATERIAL_ICONS_BASE_URL}/e-equipment.svg`,
  'eGame':  `${MATERIAL_ICONS_BASE_URL}/e-game.svg`,
};

const GREEN_FILTER = 'invert(26%) sepia(95%) saturate(1791%) hue-rotate(96deg) brightness(97%) contrast(104%)';
const GRAY_FILTER = 'invert(39%) sepia(0%) saturate(0%) hue-rotate(351deg) brightness(98%) contrast(90%)';
const RED_FILTER = 'invert(19%) sepia(97%) saturate(7498%) hue-rotate(28deg) brightness(98%) contrast(101%)';

export const AvailabilityStatusToFilterMap: {[key: string]: string} = {
  [AvailabilityStatus.AVAILABLE]: GREEN_FILTER,
  [AvailabilityStatus.CHECK_AVAILABILITY]: GRAY_FILTER,
  [AvailabilityStatus.CHECKED_OUT]: RED_FILTER,
  [AvailabilityStatus.UNAVAILABLE]: RED_FILTER,
};

export interface Icon {
  url: string,
  filter: string
}
