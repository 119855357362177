<ng-container *customerFeatureToggle="CustomerFeature.Bookmarks">
  <button *ngIf="!customLink; else bookmarksCustomLink" class="bookmarks-button"
          data-automation-id="toggle-bookmarks-btn"
          (click)="toggleBookmarks($event)"
          cdkMonitorElementFocus
          attr.aria-label="{{ 'toggleBookmarks' | translate }}"
          tippy="{{ 'toggleBookmarks' | translate }}">
    <fa-icon class="bookmarks-icon"
             [@highlight]="highlightBookmarkButton ? 'start' : 'stop'"
             (@highlight.done)="onAnimationDoneEvent($event)"
             data-automation-id="bookmarks-icon"
             [icon]="bookmarkIcon"></fa-icon>
  </button>

  <ng-template #bookmarksCustomLink>
    <a class="bookmarks-button"
       [href]="customLink"
       target="_blank"
       data-automation-id="bookmarks-link"
       (click)="onNavigateToBookmarks()"
       cdkMonitorElementFocus
       attr.aria-label="{{ 'myBookmarks' | translate }}"
       tippy="{{ 'myBookmarks' | translate }}">
    <span class="icon fas fa-bookmark bookmarks-icon"
          [@highlight]="highlightBookmarkButton ? 'start' : 'stop'"
          (@highlight.done)="onAnimationDoneEvent($event)"
          data-automation-id="bookmarks-icon"
          aria-hidden="true"></span>
    </a>
  </ng-template>
</ng-container>
