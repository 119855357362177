import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FacetResult, ResponseFacet } from 'search/facets/models/resource-facet';
import { FacetsService } from 'search/facets/services/facets.service';
import { ResourceFacetBlock } from 'search/models/filter-panel';
import { FacetSearchObject } from 'search/models/search-object';
import { RollupResultsV2, SearchRequestBody } from 'search/models/search-results';
import { SearchType } from 'search/models/search-type';
import { EntityTypes } from '../../entity/models/entity';
import { LibraryList } from '../../library-list/reducers/library-list.reducer';
import {
  BookmarksSearchFacets,
  BookmarksSearchType,
  ListCreate,
  ListItemEntity,
  ListSortField,
  ListType,
  ListUpdate,
  ListWithItemsCount,
  SearchAvailabilityFilter
} from '../models/list';
import {
  ListItemDto,
  ListItemsDto,
  ListPaginationParams,
  ListPatchItemsRequest,
  ListPatchItemsResponse,
  ListSortByString
} from '../models/list.dto';

export type ListItemsDtoWithoutSortBy = Omit<ListItemsDto, 'sortedBy'>;

@Injectable()
export class ListService {
  private static readonly listUrl = 'api/search-result/personalization/lists';
  private static readonly listSearchUrl = 'api/search-result/search/lists';

  constructor(
    private readonly http: HttpClient,
    private facetsService: FacetsService,
  ) {
  }

  public createList(name: string, items: ListItemDto[] = []): Observable<ListCreate> {
    return this.http
    .post<{ id: string }>(ListService.listUrl, {name, items}, {headers: { 'api-version': '2' }})
    .pipe(map(({id}) => ({id})));
  }

  public updateList(request: ListUpdate): Observable<ListUpdate> {
    return this.http
    .patch<void>(`${ListService.listUrl}/${request.id}/rename`, request, {headers: { 'api-version': '2' }})
    .pipe(map(() => request));
  }

  public getItems(
    id: string,
    sortBy: ListSortByString,
    paginationParams: ListPaginationParams,
    filterHiddenShowcaseItems = false,
  ): Observable<ListItemsDtoWithoutSortBy> {
    return this.http.get<ListItemsDtoWithoutSortBy>(`${ListService.listUrl}/${id}/items`, {
      params: {
        sortBy,
        pageNum: paginationParams.pageNum.toString(),
        pageSize: paginationParams.pageSize.toString(),
        filterHiddenShowcaseItems,
      },
      headers: { 'api-version': '2' }
    });
  }

  public patchItems(body: ListPatchItemsRequest): Observable<ListPatchItemsResponse> {
    if (!body.addTo.length && !body.deleteFrom.length) {
      return of({addedTo: [], deletedFrom: [], notFound: []});
    }
    return this.http.patch<ListPatchItemsResponse>(`${ListService.listUrl}/items`, body, {
      headers: { 'api-version': '2' }
    });
  }

  public makePatchItem(entity: ListItemEntity): ListItemDto {
    return {
      id: entity.id,
      entityType: entity.entityType,
      title: entity.title,
      recordId: entity.selectedTabRecordId,
    };
  }

  public deleteList(id: string): Observable<string> {
    return this.http
    .delete<void>(`${ListService.listUrl}/${id}`, {headers: { 'api-version': '2' }})
    .pipe(map(() => id));
  }

  public getList(id: string): Observable<LibraryList> {
    return this.http.get<LibraryList>(`${ListService.listUrl}/${id}`, {headers: {'api-version': '2'}});
  }

  public searchList(list: ListWithItemsCount, query: SearchRequestBody): Observable<ListWithItemsCount> {
    const pageSize = 100;
    const emptyResult: RollupResultsV2 = {
      totalResults: 0,
      data: [],
      totalPages: 0
    };
    const emptyList: ListWithItemsCount = {
      type: ListType.regular,
      id: list.id,
      name: list.name,
      pagination: list.pagination,
      items: [],
      itemsLoadingState: {
        loading: false,
        error: null
      },
      sort: list.sort,
      itemsCount: 0
    };
    const totalPages = Math.ceil(list.itemsCount / pageSize);
    let requests: Observable<RollupResultsV2>[] = [];
    for (const pageNum of Array(totalPages).keys()) {
      const pageQuery = {
        ...query,
        pageNum,
        pageSize
      };
      requests.push(this.searchListPage(list.id, pageQuery).pipe(
        catchError(err => of(emptyResult))
      ));
    }
    if (requests.length <= 0) {
      return of(emptyList);
    }

    return forkJoin(requests).pipe(
      switchMap((results) => {
        let acc: RollupResultsV2 = {
          totalResults: 0, data: [], totalPages: 0
        };
        for (const res of results) {
          acc.totalResults += res.totalResults;
          acc.data.push(...res.data);
        }
        const data = this.rollupResultsToList(acc, list);
        return of(data);
      }),
    );
  }

  private searchListPage(id: string, searchRequestBody: SearchRequestBody): Observable<RollupResultsV2> {
    const options = {
      headers: {'api-version': '1'}
    };
    return this.http.post<RollupResultsV2>(`${ListService.listSearchUrl}/${id}`, searchRequestBody, options);
  }

  private rollupResultsToList(result: RollupResultsV2, list: ListWithItemsCount): ListWithItemsCount {
    const generatedList: ListWithItemsCount = {
      id: list.id,
      name: list.name,
      type: list.type,
      pagination: {
        page: result.page,
        totalPages: result.totalPages,
        totalResults: result.totalResults,
      },
      items: result.data.map(fg => {
        const selectedTabRecordId = fg.materialTabs?.[0].editions?.[0].recordId;
        return {
          id: fg.id,
          selected: false,
          entity: {
            id: fg.id,
            coverConfig: {
              type: EntityTypes.FORMAT_GROUP,
              coverUrl: fg.coverUrl,
            },
            title: fg.title,
            sourceEntity: fg,
            entityType: EntityTypes.FORMAT_GROUP,
            selectedTabRecordId,
          }
        };
      }),
      itemsCount: result.totalResults,
      itemsLoadingState: {
        loading: false,
        error: null
      },
      sort: {
        field: 'date' as ListSortField,
        order: 'desc'
      }
    };
    return generatedList;
  }

  public getFacets(): Observable<FacetResult<ResponseFacet>[]> {
    return forkJoin(this.getFormats(), this.getLocations(), this.getLanguages());
  }

  public getFormats(): Observable<FacetResult<ResponseFacet>> {
    const query: FacetSearchObject = {
      facetKey: ResourceFacetBlock.FORMATS,
      facetMapField: 'MaterialType',
    };
    const options = {
      pageNum: 0,
      pageSize: 100
    };
    return this.facetsService.getSingleFacet(query, options);
  }

  public getLocations(): Observable<FacetResult<ResponseFacet>> {
    const query: FacetSearchObject = {
      facetKey: ResourceFacetBlock.LOCATION,
      facetMapField: 'Location',
    };
    const options = {
      pageNum: 0,
      pageSize: 100
    };
    return this.facetsService.getSingleFacet(query, options);
  }

  public getLanguages(): Observable<FacetResult<ResponseFacet>> {
    const query: FacetSearchObject = {
      facetKey: ResourceFacetBlock.LANGUAGE,
      facetMapField: 'Language',
    };
    const options = {
      pageNum: 0,
      pageSize: 100
    };
    return this.facetsService.getSingleFacet(query, options);
  }

  public getSearchBody(searchText: string,
                       availabilityFilter: SearchAvailabilityFilter,
                       searchType: BookmarksSearchType,
                       facets: BookmarksSearchFacets) {
    const searchBody = {
      resourceType: EntityTypes.FORMAT_GROUP,
      searchType: SearchType.EVERYTHING,
      searchText: '*',
      universalLimiterIds: availabilityFilter == SearchAvailabilityFilter.All ? [] : ['at_library', 'online']
    } as SearchRequestBody;
    switch (searchType) {
      case BookmarksSearchType.Format:
        searchBody.materialTypeIds = this.findMatchingIds(searchText, facets.materialType);
        break;
      case BookmarksSearchType.Language:
        searchBody.languageIds = this.findMatchingIds(searchText, facets.language);
        break;
      case BookmarksSearchType.Location:
        searchBody.locationIds = this.findMatchingIds(searchText, facets.location);
        break;
      case BookmarksSearchType.Title:
        searchBody.searchText = searchText ?? '*';
        searchBody.searchType = SearchType.TITLE;
        break;
      case BookmarksSearchType.Author:
        searchBody.searchText = searchText ?? '*';
        searchBody.searchType = SearchType.AGENT;
        break;
      case BookmarksSearchType.DateFrom:
        searchBody.dateFrom = parseInt(searchText);
        break;
      case BookmarksSearchType.Series:
        searchBody.searchText = searchText ?? '*';
        searchBody.searchType = SearchType.SERIES;
        break;
      case BookmarksSearchType.Concept:
        searchBody.searchText = searchText ?? '*';
        searchBody.searchType = SearchType.CONCEPT;
        break;
    }

    return searchBody;
  }

  private findMatchingIds(searchText: string, facet: FacetResult<ResponseFacet>) {
    const ids = facet.data.filter(value => value.label.toLowerCase().includes(searchText)).map(value => value.id);
    return ids;
  }

}
