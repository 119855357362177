import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { areEqualDeeply } from 'shared/utils/are-equal-deeply';
import { FormatGroup } from '../../../../../entity/models/entity';
import { deleteNlbBookmark, tryToCreateNlbBookmark } from '../../actions/nlb-bookmark.actions';
import { NlbBookmark } from '../../models/nlb-bookmark';
import { getNlbBookmarks, getToggleState, ToggleState } from '../../reducers/nlb-bookmark.reducer';
import { faBookmark as savedBookmarkIcon } from '@fortawesome/pro-solid-svg-icons';
import { faBookmark as bookmarkIcon } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-nlb-bookmark-button',
  templateUrl: './nlb-bookmark-button.component.html',
  styleUrls: ['./nlb-bookmark-button.component.scss'],
})
export class NlbBookmarkButtonComponent implements OnInit, OnDestroy {
  @Input() public formatGroup: FormatGroup;
  @Input() public tabName: string;
  @Input() public ariaDescribedBy = '';
  public toggleState?: ToggleState;
  public isSaved: boolean;
  public readonly savedBookmarkIcon = savedBookmarkIcon;
  public readonly bookmarkIcon = bookmarkIcon;
  private bookmark: NlbBookmark;
  private fgRecordIds: string[];
  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {
    this.fgRecordIds = this.formatGroup.materialTabs.flatMap((materialTab) => materialTab.recordIds);

    this.subscription.add(
      this.store.select(getNlbBookmarks).subscribe((bookmarks) => {
        this.setBookmark(bookmarks);
        this.cdr.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getToggleState, {id: this.formatGroup.id})
      .pipe(distinctUntilChanged(areEqualDeeply))
      .subscribe((toggleState) => {
        this.toggleState = toggleState;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onToggleBookmark(): void {
    if (this.toggleState?.loading) {
      return;
    }
    if (this.bookmark) {
      this.deleteBookmark();
    } else {
      this.createBookmark();
    }
  }

  private deleteBookmark(): void {
    this.store.dispatch(deleteNlbBookmark({
      nlbBookmark: this.bookmark,
      formatGroup: this.formatGroup,
    }));
  }

  private createBookmark(): void {
    this.store.dispatch(tryToCreateNlbBookmark({
      tabName: this.tabName,
      formatGroup: this.formatGroup,
    }));
  }

  private setBookmark(bookmarks: NlbBookmark[]): void {
    this.bookmark = bookmarks.find((bookmark) => this.fgRecordIds.includes(bookmark.recordId));
    this.isSaved = !!this.bookmark;
  }
}
