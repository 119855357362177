<div class="d-flex">
  <input #searchInput
         class="search-input"
         aria-label="What are you looking for?"
         (keydown.enter)="onKeydownEnter(searchInput.value)"
         [value]=""
         data-automation-id="search-bar-input">
  <app-custom-dropdown class="search-dropdown"
                       data-automation-id="search-bar-dropdown"
                       [overlayOffsetX]="0"
                       cdkMonitorElementFocus
                       [selectedOption]="searchType"
                       (change)="onSearchTypeChange($event)"
                       [aria-label]="'searchBy' | translate">
    <app-custom-dropdown-option
      *ngFor="let type of searchTypes"
      class="search-dropdown"
      [class.search-bar-dropdown-option-small]="false"
      [value]="type.id"
      [id]="type.id"
      translate>{{ type.label }}
    </app-custom-dropdown-option>
  </app-custom-dropdown>
  <div class="availability-filter-toggle align-self-start ml-auto">
    <button (click)="toggleAvailability(SearchAvailabilityFilter.All)"
            [class.active]="availabilityFilter === SearchAvailabilityFilter.All"
            [attr.aria-pressed]="availabilityFilter === SearchAvailabilityFilter.All"
            data-automation-id="availability-filter-toggle-all-button"
            class="mr-1">
      <span class="text ml-1">All</span>
    </button>
    <button (click)="toggleAvailability(SearchAvailabilityFilter.Available)"
            [class.active]="availabilityFilter === SearchAvailabilityFilter.Available"
            [attr.aria-pressed]="availabilityFilter === SearchAvailabilityFilter.Available"
            data-automation-id="availability-filter-toggle-available-button">
      <span class="text ml-1">Available</span>
    </button>
  </div>
</div>
