import { Component, EventEmitter, Output } from '@angular/core';
import { DropdownValue } from 'common/models/custom-dropdown';
import { SearchAvailabilityFilter, BookmarksSearchType, BookmarksSearchTypes } from '../../models/list';

@Component({
  selector: 'app-searchable-bookmarks-search-bar',
  templateUrl: './searchable-bookmarks-search-bar.component.html',
  styleUrls: ['./searchable-bookmarks-search-bar.component.scss']
})
export class SearchableBookmarksSearchBarComponent {
  @Output() public onAvailabilityToggle = new EventEmitter<string>();
  @Output() public onSearchTypeSelect = new EventEmitter<string>();
  @Output() public onSearch = new EventEmitter<string>();
  public searchTypes = BookmarksSearchTypes;
  public searchType: BookmarksSearchType = BookmarksSearchType.Title;
  public availabilityFilter: SearchAvailabilityFilter = SearchAvailabilityFilter.All;
  protected readonly SearchAvailabilityFilter = SearchAvailabilityFilter;
  public searchText = '*';

  public onKeydownEnter(value: string) {
    const parsed = value != '' ? value : '*';
    if (parsed === this.searchText) return;
    this.searchText = parsed;
    this.onSearch.emit(this.searchText);
  }

  public onSearchTypeChange(event: DropdownValue<BookmarksSearchType>) {
    this.searchType = event.selected;
    this.onSearchTypeSelect.emit(event.selected);
  }

  public toggleAvailability(value: SearchAvailabilityFilter) {
    if (value == this.availabilityFilter) return;
    this.availabilityFilter = value;
    this.onAvailabilityToggle.emit(value);
  }
}
