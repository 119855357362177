<div class="profile-card card" [class.has-expiration-message]="user.profileConfiguration.expirationMessage">

  <app-profile-expiration-message
    [expirationMessage]="user.profileConfiguration.expirationMessage"
    [expirationDate]="user.expirationDate"
    [libraryContactLink]="user.profileConfiguration.libraryContactLink">
  </app-profile-expiration-message>

  <div aria-atomic="true" aria-live="assertive" role="alert">
    <div *ngIf="serverError"
         class="invalid-feedback d-block mb-2 profile-card-form-error"
         data-automation-id="profile-card-form-error-message">
      <ng-container *ngIf="serverError === 'validation'">
        {{ 'profileFieldErrorServerValidation' | translate }}
      </ng-container>
      <ng-container *ngIf="serverError === 'forbidden'">
        {{ 'profileFieldErrorServerForbidden' | translate }}
      </ng-container>
      <ng-container *ngIf="serverError === 'unknown'">
        {{ 'profileFieldErrorServerUnknown' | translate }}
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="passcodeUpdateSuccess"
    class="mx-sm-9 mx-2 px-3 py-1 mt-5 d-flex justify-content-between align-items-center passcode-update-success-message"
    aria-live="assertive"
    role="status"
    data-automation-id="passcode-update-success-message">
    <div>
      <span class="fas fa-check-circle mr-1" aria-hidden="true"></span>
      <span translate class="ml-1">passcodeUpdateSuccessMessage</span>
    </div>
    <button
      data-automation-id="passcode-update-success-message-close-btn"
      type="button"
      class="close-btn p-0"
      [attr.aria-label]="'close' | translate"
      (click)="resetPasscodeUpdateState()">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div [formGroup]="form" [hidden]="isLoading" class="profile-card-form w-100 mx-sm-5 mx-1 my-7 " data-automation-id="profile-card-form">
    <!-- NAME -->
    <app-profile-form-field *ngIf="isEditing"
                            [icon]="userIcon"
                            [inputLabel]="'profileName' | translate"
                            [isEditing]="isEditing"
                            [libraryContactLink]="user.profileConfiguration.libraryContactLink"
                            [serverError]="serverFieldsError.name"
                            [autocompleteValue]="'name'"
                            data-automation-id="profile-card-name"
                            formControlName="name">
    </app-profile-form-field>

    <!-- NICKNAME -->
    <app-profile-form-field *ngIf="user.nickname || isEditing"
                            [icon]="userIcon"
                            [inputLabel]="'profileNickname' | translate"
                            [isEditing]="isEditing"
                            [libraryContactLink]="user.profileConfiguration.libraryContactLink"
                            [serverError]="serverFieldsError.nickname"
                            [autocompleteValue]="'nickname'"
                            data-automation-id="profile-card-nickname"
                            formControlName="nickname">
    </app-profile-form-field>

    <!-- EMAIL -->
    <app-profile-form-field *ngIf="user.emails.length || isEditing"
                            [icon]="emailIcon"
                            [inputLabel]="'profileEmailAddress' | translate"
                            [isEditing]="isEditing"
                            [libraryContactLink]="user.profileConfiguration.libraryContactLink"
                            [serverError]="serverFieldsError.emails"
                            data-automation-id="profile-card-emails"
                            formArrayName="emails">
      <ng-template inputDisplay>
        <app-profile-form-field-input-multiple [inputLabel]="'profileEmailAddress' | translate"
                                               [autocompleteValue]="'email'"
                                               (onAddControl)="addEmailField()">
        </app-profile-form-field-input-multiple>
      </ng-template>
    </app-profile-form-field>

    <!-- ADDRESS -->
    <app-profile-form-addresses (onAddControl)="addAddressField()"
                                *ngIf="user.addresses.length || isEditing"
                                [isEditing]="isEditing"
                                [libraryContactLink]="user.profileConfiguration.libraryContactLink"
                                [touchForm$]="touchForm$"
                                [serverError]="serverFieldsError.addresses"
                                formArrayName="addresses">
    </app-profile-form-addresses>

    <!-- MOBILE PHONE -->
    <app-profile-form-field *ngIf="user.mobilePhones.length || isEditing"
                            [icon]="mobileIcon"
                            [inputLabel]="'profileMobilePhoneNumber' | translate"
                            [isEditing]="isEditing"
                            [libraryContactLink]="user.profileConfiguration.libraryContactLink"
                            [serverError]="serverFieldsError.mobilePhones"
                            data-automation-id="profile-card-mobile-phones"
                            formArrayName="mobilePhones">
      <ng-template inputDisplay>
        <app-profile-form-field-mobile-phone-multiple
          [carriers]="carriers"
          [inputLabel]="'profileMobilePhoneNumber' | translate"
          [inputId]="'profileMobilePhoneNumber' | translate"
          (onAddControl)="addMobilePhoneFormGroup()">
        </app-profile-form-field-mobile-phone-multiple>
      </ng-template>
      <ng-template valueDisplay let-value="value">
        <div>{{value?.phone}}</div>
      </ng-template>
    </app-profile-form-field>

    <!-- HOME PHONE -->
    <app-profile-form-field *ngIf="user.homePhones.length || isEditing"
                            [icon]="mobileIcon"
                            [inputLabel]="'profileHomePhoneNumber' | translate"
                            [isEditing]="isEditing"
                            [libraryContactLink]="user.profileConfiguration.libraryContactLink"
                            [serverError]="serverFieldsError.homePhones"
                            data-automation-id="profile-card-home-phones"
                            formArrayName="homePhones">
      <ng-template inputDisplay>
        <app-profile-form-field-input-multiple [inputLabel]="'profileHomePhoneNumber' | translate"
                                               [autocompleteValue]="'tel'"
                                               (onAddControl)="addHomePhoneField()">
        </app-profile-form-field-input-multiple>
      </ng-template>
    </app-profile-form-field>

    <!-- HOME LIBRARY -->
    <app-profile-form-field *ngIf="user.homeLibraryCode || isEditing"
                            [icon]="homeIcon"
                            [inputLabel]="'profileHomeLibrary' | translate"
                            [isEditing]="isEditing"
                            [libraryContactLink]="user.profileConfiguration.libraryContactLink"
                            [serverError]="serverFieldsError.homeLibraryCode"
                            data-automation-id="profile-card-home-library"
                            formControlName="homeLibraryCode">
      <ng-template inputDisplay>
        <app-profile-form-field-input-select
          [formControl]="homeLibraryCode"
          [inputLabel]="'profileHomeLibrary' | translate"
          [values]="pickupLocations">
        </app-profile-form-field-input-select>
      </ng-template>
      <ng-template valueDisplay>{{ 'location.' + user.homeLibraryCode | translate }}</ng-template>
    </app-profile-form-field>

    <!-- PREFERRED PICKUP LOCATION -->
    <app-profile-form-field *ngIf="(user.preferredPickupLocationCode || isEditing) && !hidePreferredLocations"
                            [icon]="homeIcon"
                            [inputLabel]="'profilePreferredPickupLocation' | translate"
                            [isEditing]="isEditing"
                            [libraryContactLink]="user.profileConfiguration.libraryContactLink"
                            [serverError]="serverFieldsError.preferredPickupLocationCode"
                            data-automation-id="profile-card-preferred-pickup-location"
                            formControlName="preferredPickupLocationCode">
      <ng-template inputDisplay>
        <app-profile-form-field-input-select
          [formControl]="preferredPickupLocationCode"
          [inputLabel]="'profilePreferredPickupLocation' | translate"
          [values]="pickupLocations">
        </app-profile-form-field-input-select>
      </ng-template>
      <ng-template valueDisplay>{{ 'location.' + user.preferredPickupLocationCode | translate }}</ng-template>
    </app-profile-form-field>

    <!-- PICKUP AREAS (POLARIS) -->
    <ng-container *ngIf="ilsType === IlsType.polaris && isPickupAreaFeatureEnabled">
      <app-profile-form-field *ngIf="(user.preferredPickupLocationAreaCode || isEditing)"
                              [icon]="homeIcon"
                              [inputLabel]="'profilePickupArea' | translate"
                              [isEditing]="isEditing"
                              data-automation-id="profile-card-preferred-pickup-area"
                              formControlName="preferredPickupLocationAreaCode">
        <ng-template inputDisplay>
          <app-profile-form-field-input-select
            [formControl]="preferredPickupLocationAreaCode"
            [inputLabel]="'profilePickupArea' | translate"
            [values]="pickupAreas">
          </app-profile-form-field-input-select>
        </ng-template>
        <ng-template valueDisplay>{{selectedArea?.value}}</ng-template>
      </app-profile-form-field>
    </ng-container>

    <!-- REGISTERED LIBRARY (POLARIS) -->
    <app-profile-form-field *ngIf="user.registeredBranch"
                            [icon]="homeIcon"
                            [inputLabel]="'profileRegisteredBranch' | translate"
                            [libraryContactLink]="user.profileConfiguration.libraryContactLink"
                            data-automation-id="profile-card-registered-branch"
                            formControlName="registeredBranch">
      <ng-template valueDisplay>{{ 'location.' + user.registeredBranchId | translate }}</ng-template>
    </app-profile-form-field>

    <!-- NOTIFICATION PREFERENCE -->
    <div [formGroup]="notificationPreference">
      <app-profile-form-field
        [fieldName]="'notificationPreference' | translate"
        [icon]="notificationIcon"
        [inputLabel]="'notificationPreference' | translate"
        [isEditing]="isEditing"
        [libraryContactLink]="user.profileConfiguration.libraryContactLink"
        [serverError]="serverFieldsError.notificationPreference"
        data-automation-id="profile-notification-preference"
        formControlName="channel">
        <ng-template inputDisplay>
          <profile-form-field-input-select-extended
            *ngIf="usePreselectedPhone; else noPreselectedPhone"
            [values]="notificationPreferenceOptions">
          </profile-form-field-input-select-extended>

          <ng-template #noPreselectedPhone>
            <app-profile-form-field-input-select
              [formControl]="channel"
              [inputLabel]="'notificationPreference' | translate"
              [values]="notificationPreferenceOptions">
            </app-profile-form-field-input-select>
          </ng-template>
        </ng-template>
        <ng-template valueDisplay>
          {{channel.value === 'none' ? ('noPreferenceSelected' | translate) : channel.value | translate}}
        </ng-template>
      </app-profile-form-field>
    </div>

    <!-- CARD NUMBER -->
    <app-profile-form-field *ngIf="user.cardNumber || isEditing"
                            [fieldName]="'profileCard' | translate"
                            [icon]="cardIcon"
                            [inputLabel]="'profileCardNumber' | translate"
                            [isEditing]="isEditing"
                            [libraryContactLink]="user.profileConfiguration.libraryContactLink"
                            [autocompleteValue]="'cc-number'"
                            data-automation-id="profile-card-card-number"
                            formControlName="cardNumber">
    </app-profile-form-field>

    <!-- EXPIRATION DATE -->
    <app-profile-form-field *ngIf="user.expirationDate"
                            [hideHelp]="true"
                            [icon]="expirationDateIcon"
                            [inputLabel]="'profileExpirationDate' | translate"
                            [isEditing]="isEditing"
                            [libraryContactLink]="user.profileConfiguration.libraryContactLink"
                            [autocompleteValue]="'cc-exp'"
                            data-automation-id="profile-card-expiration-date"
                            formControlName="expirationDate">
      <ng-template valueDisplay>{{user.expirationDate | date}}</ng-template>
    </app-profile-form-field>

    <!-- PASSCODE -->
    <div class="d-flex">
      <app-profile-form-field
        [hideHelp]="true"
        [icon]="passcodeIcon"
        [inputLabel]="'passcode' | translate"
        [isEditing]="isEditing"
        [libraryContactLink]="user.profileConfiguration.libraryContactLink"
        data-automation-id="profile-passcode"
        formControlName="passcode">
        <ng-template valueDisplay>{{passcode.value}}</ng-template>
      </app-profile-form-field>
      <button
        *ngIf="isEditing && user.profileConfiguration.fieldsConfiguration.passcode"
        translate
        data-automation-id="profile-change-passcode-btn"
        class="ins-buttons button-secondary flint my-1 ml-5"
        (click)="openChangePasscodeDialog()"
      >
        changePasscode
        <fa-icon [icon]="editIcon" class="ml-2"></fa-icon>
      </button>
    </div>

    <!-- READING HISTORY -->
    <ins-bs-checkbox *ngIf="isReadingHistoryEnabled && isEnableReadingHistorySettingStatus" data-automation-id="profile-reading-history-checkbox"
                     (click)="onChange()"
                     [checked]="keepReadingHistoryStatus"
                     [disabled]="disableCheckbox"
                     [aria-label]="'keepReadingHistory' | translate">
      <span class="keep-reading-history" translate>keepReadingHistory</span>
    </ins-bs-checkbox>
  </div>

  <div class="d-flex justify-content-center" role="status" aria-live="polite">
    <div *ngIf="isLoading"
         class="mt-5 spinner-border text-primary profile-card-form-loading-spinner"
         data-automation-id="profile-card-form-loading-spinner">
      <span class="sr-only" translate>Loading...</span>
    </div>
  </div>
</div>
