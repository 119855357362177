import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnInit, } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { LocationWithName } from 'app/models/locations';
import { Observable, Subject, Subscription } from 'rxjs';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { IlsType } from 'shared/models/ilsType';
import { loadTabHold, updateShouldCloseModal } from 'user/actions/user-profile.actions';
import { GetItForm, HoldNeededDateForm } from 'user/models/get-it';
import { BorrowByMailConfiguration, UserAddress } from 'user/models/user';
import { getErrorMessage, getShouldCloseModal, isLoading, UserState } from 'user/reducers/user.reducer';
import { CustomerFeature } from '../../../customer-integration/customer-integration';

@Component({
  selector: 'app-get-it-dialog',
  templateUrl: './get-it-dialog.component.html',
  styleUrls: ['./get-it-dialog.component.scss'],
  animations: [
    trigger('collapsible', [
      state('void', style({ height: '0' })),
      state('*', style({ height: '*' })),
      transition('* <=> *', animate('200ms')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetItDialogComponent implements OnInit {
  @Input() public pickupLocations: LocationWithName[];
  @Input() public selectedLocationCode: string;
  @Input() public volume: string;
  @Input() public itemVolume: string;
  @Input() public borrowByMailConfiguration?: BorrowByMailConfiguration;
  @Input() public holdExpirationDefault: number;
  @Input() public holdActivationDateSetting: boolean;
  @Input() public userAddress: UserAddress;
  @Input() public errorMessage: string;
  public getItForm: FormGroup<GetItForm>;
  public ilsType: IlsType;
  public formDataSubject = new Subject<any>();
  public isLoading$: Observable<boolean>;
  public readonly CustomerFeature = CustomerFeature;
  private readonly subscription = new Subscription();

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly store: Store<UserState>,
    private readonly configLoader: ConfigurationLoader,
  ) {}

  public ngOnInit() {
    this.getItForm = this.fb.group<GetItForm>({
      pickupLocation: this.fb.control<string>(this.selectedLocationCode, Validators.required),
      borrowByMail: this.fb.control<boolean>(false),
      holdNeededDate: this.fb.group<HoldNeededDateForm>({
        hasExpirationDate: this.fb.control<boolean>(false),
      }),
    });

    this.ilsType = this.configLoader.ilsType;
    this.isLoading$ = this.store.select(isLoading);

    if (!this.pickupLocations?.length) {
      this.pickupLocation.disable();
    }
  }

  public onClose() {
    this.formDataSubject.error({});
    this.store.dispatch(updateShouldCloseModal({ errorMessage: '' }));
    this.activeModal.dismiss();
  }

  public onSubmit() {
    this.store.dispatch(loadTabHold());
    this.formDataSubject.next(this.getItForm.getRawValue());
    this.subscription.add(
      this.store.select(getShouldCloseModal).subscribe((shouldCloseModal) => {
        if (shouldCloseModal) {
          this.activeModal.close();
        }
      }),
    );

    this.subscription.add(
      this.store.select(getErrorMessage).subscribe((message) => {
        if (message) {
          this.errorMessage = message;
        }
      }),
    );
  }

  public onBorrowByMailChange(checked: boolean) {
    this.getItForm.patchValue({
      borrowByMail: checked,
    });
    if (checked) {
      this.pickupLocation.disable();
    } else {
      this.pickupLocation.enable();
    }
  }

  public get borrowByMail(): AbstractControl {
    return this.getItForm.get('borrowByMail');
  }

  public get pickupLocation(): AbstractControl {
    return this.getItForm.get('pickupLocation');
  }
}
