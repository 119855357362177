<div *ngIf="singleResultItem; else multipleSearchItem" class="custom-passthrough-container"
     [ngClass]="{'custom-passthrough-container-vertical' : isFullCardWithTitleAndImage}"
     data-automation-id="custom-passthrough-single">
    <span class="custom-passthrough-label d-none d-lg-block"
          data-automation-id="custom-passthrough-find-from" translate>findFrom
    </span>
  <a (click)="$event.preventDefault(); openLink();"
     href=""
     [tippy]="selectedCustomPassThrough?.params?.name || selectedCustomPassThrough?.params?.systemName"
     [attr.aria-label]="('findFrom' | translate) + ' '
            + selectedCustomPassThrough?.params?.name || selectedCustomPassThrough?.params?.systemName"
     class="custom-passthrough-link"
     data-automation-id="custom-passthrough-link">
    <app-custom-pass-through-item
      [item]="selectedCustomPassThrough"
      [vertical]="isFullCardWithTitleAndImage"></app-custom-pass-through-item>
    <span *ngIf="!isFullCardWithTitleAndImage" data-automation-id="custom-passthrough-icon"
          class="fa fa-external-link-alt p-2 icon"></span>
  </a>
</div>

<ng-template #multipleSearchItem>
  <div *ngIf="this.customPassThroughData?.length" class="d-flex flex-column">
    <span class="custom-passthrough-label d-none d-lg-block"
          data-automation-id="custom-passthrough-find-from" translate>findFrom
    </span>
    <div class="custom-passthrough-container-list" data-automation-id="custom-passthrough-list">
      <div class="custom-passthrough-list">
        <app-custom-dropdown [overlayOffsetX]="dropDownOffset"
                            [fixedWidth]="!!searchData"
                            (change)="selectCustomPass($event)"
                            [selectedOption]="selectedCustomPassThrough?.connectionId"
                            [aria-label]="'findFrom' | translate"
                            data-automation-id="customPassThroughDropDown">
          <app-custom-dropdown-option
            *ngFor="let item of customPassThroughData"
            [value]="item?.connectionId"
            [image]="!item?.params?.hideSystemName ? (item?.logoUrl || item?.params?.imageUrl) : null"
            [id]="item?.params?.name || item?.params?.systemName"
            data-automation-id="customPassThroughOption">

            <app-custom-pass-through-item [item]="item" [fixedWidth]="!!searchData">
            </app-custom-pass-through-item>
          </app-custom-dropdown-option>
        </app-custom-dropdown>
      </div>

      <a (click)="$event.preventDefault(); openLink();"
        [tippy]="selectedCustomPassThrough?.params?.name || selectedCustomPassThrough?.params?.systemName"
        [class]="(selectedCustomPassThrough ? 'active' : 'inactive')"
        [attr.aria-label]="('findFrom' | translate) + ' '
                + selectedCustomPassThrough?.params?.name || selectedCustomPassThrough?.params?.systemName"
        class="custom-passthrough-link"
        target="_blank"
        href=""
        [tabindex]="selectedCustomPassThrough ? 0 : -1"
        data-automation-id="custom-passthrough-link">
        <span data-automation-id="custom-passthrough-icon" class="fa fa-external-link-alt p-2 icon"></span>
      </a>
    </div>
  </div>
</ng-template>
