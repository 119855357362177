import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LibraryInfoActions from 'core/actions/library-info.actions';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DictionariesService } from '../../services/dictionaries.service';
import { PickupArea } from 'app/models/locations';

@Injectable()
export class LibraryInfoEffects {

  public loadVisibleLocations$ = createEffect(() => this.actions$.pipe(
    ofType(LibraryInfoActions.loadVisibleLocations),
    switchMap(({configurationId}) => {
      return this.dictionariesService.loadVisibleLocations(configurationId)
      .pipe(
        map((locations) => {
          return LibraryInfoActions.loadVisibleLocationsSuccess({
            visibleLocations: this.dictionariesService.getVisibleLocationsWithName(locations)
          });
        }),
        catchError((err: HttpErrorResponse) => {
          return of(LibraryInfoActions.loadVisibleLocationsFailure({errorCode: err.status}));
        }),
      );
    }),
  ));

  public loadLocationInfo$ = createEffect(() => this.actions$.pipe(
    ofType(LibraryInfoActions.loadLocationInfo),
    switchMap(({configurationId, locationId}) => {
      return this.dictionariesService.loadLocationInfo(configurationId, locationId)
      .pipe(
        map((locationInfo) => {
          return LibraryInfoActions.loadLocationInfoSuccess({locationInfo});
        }),
        catchError((error: HttpErrorResponse) => {
          return of(LibraryInfoActions.loadLocationInfoFailure({errorCode: error.status}));
        }),
      );
    }),
  ));

  public loadPickupArea$ = createEffect(() => this.actions$.pipe(
    ofType(LibraryInfoActions.loadPickupArea),
    switchMap(({pickupLocationCode}) => {
      return this.dictionariesService.loadPickupArea(pickupLocationCode)
      .pipe(
        map((pickupArea: PickupArea[]) => {
          return LibraryInfoActions.loadPickupAreaSuccess({pickupArea});
        }),
        catchError((error: HttpErrorResponse) => {
          return of(LibraryInfoActions.loadPickupAreaFailure({errorCode: error.status}));
        }),
      );
    }),
  ));


  constructor(
    private readonly actions$: Actions,
    private dictionariesService: DictionariesService,
  ) {
  }
}
