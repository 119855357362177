<div class="d-inline-block" ngbDropdown placement="left-top auto">
  <button #optionsMenuToggleButton
          [attr.aria-describedby]="ariaDescribedBy"
          [attr.aria-label]="'options' | translate"
          [ngClass]="{
            'small-button': smallButton,
            'button-labelled': !smallButton,
            'options-toggle-menu-button-disabled': disabled,
          }"
          aria-expanded="false"
          aria-haspopup="menu"
          class="app-button options-toggle-button"
          data-automation-id="options-toggle-menu-button"
          [disabled]="disabled"
          tippy="{{ 'options' | translate }}"
          ngbDropdownToggle
          type="button">
    <span aria-hidden="true" class="icon options-icon" [ngClass]="smallButton ? 'icon-more' : 'icon-overflow'"></span>
    <span *ngIf="!smallButton" class="text" translate>options</span>
  </button>
  <div class="py-2" ngbDropdownMenu role="menu" data-automation-id="options-dropdown-menu">
    <button (click)="onEmailAction()"
            [attr.aria-label]="'emailButton' | translate"
            [attr.aria-describedby]="ariaDescribedBy"
            class="d-block py-2 px-3 w-100 h-auto app-button option-button"
            data-automation-id="option-email-button"
            aria-haspopup="dialog"
            ngbDropdownItem
            role="menuitem">
      <span aria-hidden="true" class="option-button-icon icon-email"></span>
      <span class="option-button-label ml-2 text-capitalize" translate>emailButton</span>
    </button>
    <button *ngIf="isCitationVisible"
            (click)="onCitationAction()"
            class="d-block py-2 px-3 w-100 h-auto app-button option-button"
            data-automation-id="option-citation-button"
            [attr.aria-label]="'exportCitation' | translate"
            [attr.aria-describedby]="ariaDescribedBy"
            aria-haspopup="dialog"
            ngbDropdownItem
            role="menuitem">
      <fa-icon [icon]="quoteIcon" [classes]="['option-button-icon', 'citation']"></fa-icon>
      <span class="option-button-label ml-2 text-capitalize" translate>exportCitation</span>
    </button>
  </div>
</div>
