<div class="h-100 w-200 position-relative clearfix"
     [@openClose]="listOpenCloseState"
     (@openClose.start)="onOpenCloseAnimation($event)"
     (@openClose.done)="onOpenCloseAnimation($event)">
  <div class="w-50 h-100 float-left">
    <ng-container [class.invisible]="stepDisplayed === stepDisplayedEnum.singleList">
      <app-bookshelf-template>
        <ng-template bookshelfTemplateTitle>
          <span data-automation-id="bookmarks-title" class="p-4" role="heading" aria-level="1">
            {{ 'bookmarksListsTitle' | translate }} | {{ lists.length }}
          </span>
        </ng-template>
        <ng-template bookshelfTemplateBody>
          <app-searchable-bookmarks-search-bar
            (onAvailabilityToggle)="onAvailabilityToggle($event)"
            (onSearchTypeSelect)="onSearchTypeChange($event)"
            (onSearch)="onSearch($event)"
          ></app-searchable-bookmarks-search-bar>
          <app-searchable-bookmarks-result-list></app-searchable-bookmarks-result-list>
          <app-lists-list [lists]="lists"></app-lists-list>
        </ng-template>
      </app-bookshelf-template>
    </ng-container>
  </div>

  <div class="w-50 h-100 float-right">
    <ng-container *ngIf="openedList" [class.invisible]="stepDisplayed === stepDisplayedEnum.lists">
      <app-searchable-bookmarks-opened-list
      [lists]="lists"
      (closeList)="closeList()"
      ></app-searchable-bookmarks-opened-list>
    </ng-container>
  </div>
</div>
