import { Injectable } from '@angular/core';
import { WindowRefService } from '../../services/window-ref.service';

export interface ScriptContent {
  text?: string;
  src?: string;
  crossOrigin?: string;
}

export interface ParsedCode {
  sanitizedHtml: string;
  scripts: ScriptContent[];
}

@Injectable()
export class ScriptLoaderService {
  private loadedScripts: Set<string> = new Set();

  constructor(
    private readonly windowRef: WindowRefService,
  ) {
  }

  public loadScript(script: ScriptContent): Promise<void> {
    return new Promise((resolve) => {
      if (script.src && this.loadedScripts.has(script.src)) {
        resolve(); // script already loaded
        return;
      }

      const scriptElement = this.windowRef.nativeWindow().document.createElement('script');
      if (script.src) {
        scriptElement.src = script.src;
      }
      if (script.crossOrigin) {
        scriptElement.crossOrigin = script.crossOrigin;
      }
      scriptElement.text = script.text || '';
      scriptElement.onload = () => {
        if (script.src) {
          this.loadedScripts.add(script.src);
        }
        resolve();
      };
      scriptElement.onerror = resolve;
      this.windowRef.nativeWindow().document.head.appendChild(scriptElement);
    });
  }

  public sanitizeHtmlAndParseScripts(htmlString: string): ParsedCode {
    const doc = this.windowRef.nativeWindow().document.implementation.createHTMLDocument();
    doc.body.innerHTML = htmlString; // Parse HTML properly
    const scriptElements = doc.getElementsByTagName('script');
    const parsedScripts = [...scriptElements].map((el: HTMLScriptElement) => {
      return {src: el.src, text: el.textContent, crossOrigin: el.crossOrigin};
    });
    // remove scripts from parsed HTML
    let i = scriptElements.length;
    while (i--) {
      scriptElements[i].parentNode.removeChild(scriptElements[i]);
    }
    return {scripts: parsedScripts, sanitizedHtml: doc.body.innerHTML};
  }
}
